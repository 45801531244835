<template>
    <v-expansion-panel v-if="totalCases > 0">
        <v-expansion-panel-header>
            <v-row align="center" no-gutters>
                <div class="mr-4">{{ ksg.name }}</div>
                <v-col> {{ ksg.full_name }}</v-col>
                <p class="my-0 mx-2 text-no-wrap">
                    Тариф: {{ priceNormalized(ksgPrice) }}
                </p>
                <p class="my-0 mx-2 text-no-wrap">{{ totalCases }} случаев</p>
                <p class="my-0 mx-2 text-no-wrap">
                    {{
                        (Math.round(totalPrice * 100) / 100).toLocaleString(
                            "ru-RU"
                        )
                    }}
                    ₽
                </p>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Код</th>
                            <th>Схема</th>
                            <th>Случаи</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ksg-scheme
                            v-for="scheme in schemes"
                            :key="scheme.id"
                            :scheme="scheme"
                            :type="type"
                        />
                    </tbody>
                </template>
            </v-simple-table>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import KsgScheme from "@/components/ProjectResults/KsgResults/KsgFinal/Stationary/KsgScheme.vue";

export default {
    components: {
        KsgScheme
    },
    props: {
        ksg: Object,
        projectInfo: Object,
        schemes: Array,
        type: String
    },
    data: () => ({
        cases: 0
    }),
    computed: {
        ksgPrice() {
            const kz = Number(this.ksg.kz);
            const zp = Number(this.ksg.zp);
            const ku = Number(this.ksg.pivot.ku);
            const skus = Number(
                this.type === "ds"
                    ? this.projectInfo.ds_skus
                    : this.projectInfo.ks_skus
            );
            const bs = Number(
                this.type === "ds"
                    ? this.projectInfo.ds_bs
                    : this.projectInfo.ks_bs
            );
            const kd = Number(this.projectInfo.kd);
            return bs * kz * (1 - zp + zp * ku * skus * kd);
        },
        totalCases() {
            return this.schemes.reduce(
                (a, b) => a + (this.type === "ds" ? b.cases.ds : b.cases.ks),
                0
            );
        },
        totalPrice() {
            return this.ksgPrice * this.totalCases;
        }
    }
};
</script>
