<template>
    <tr :class="{ 'grey lighten-3': scheme.scheme.ksg_type !== 3 }">
        <td>
            {{ scheme.scheme.name }}
        </td>
        <td>
            {{ scheme.scheme.description }}
        </td>
        <td>
            {{ type === "ds" ? scheme.cases.ds : scheme.cases.ks }}
        </td>
        <td style="min-height: 52px; height: 52px; text-align: right" right>
            <v-btn
                @click="$emit('changeScheme')"
                rounded
                small
                v-if="scheme.scheme.ksg_type === 3"
            >
                Перераспределить
            </v-btn>
        </td>
    </tr>
</template>

<script>
export default {
    props: ["scheme", "type"]
};
</script>
