<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="px-4">
            <v-row no-gutters align="center">
                <v-col> {{ drug.name }}</v-col>
                <p class="my-0 mx-2">{{ priceNormalized(drug.total_price) }}</p>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <ksg-packs :packs="drug.ksg_packs" />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
import KsgPacks from "./KsgPacks.vue";

export default {
    components: {
        KsgPacks
    },
    props: ["drug"]
};
</script>
