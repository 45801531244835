<template>
    <v-card class="mx-4" min-width="170px">
        <v-tooltip nudge-bottom="25" top>
            <template v-slot:activator="{ on, attrs }">
                <v-slide-x-transition group leave-absolute>
                    <v-card-title
                        :key="1"
                        class="justify-center"
                        style="min-height: 70px;"
                        v-bind="attrs"
                        v-if="!editMode"
                        v-on="on"
                    >
                        <slot />
                        {{ param.toLocaleString("ru-RU") }}
                    </v-card-title>
                    <v-card-title
                        :key="2"
                        class="justify-center"
                        style="min-height: 70px;"
                        v-if="editMode"
                    >
                        <v-text-field
                            :max="max"
                            :min="min"
                            :step="min"
                            dense
                            hide-details
                            solo
                            type="number"
                            v-model="newValue"
                        ></v-text-field>
                    </v-card-title>
                </v-slide-x-transition>
            </template>

            <span>{{ tip }}</span>
        </v-tooltip>
        <v-divider />
        <v-slide-x-transition group leave-absolute>
            <v-card-actions
                :key="1"
                class="justify-space-between"
                v-if="!editMode"
            >
                <v-btn @click="changeParam">Изменить</v-btn>
                <v-btn @click="resetParam" icon outlined>
                    <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
            </v-card-actions>
            <v-card-actions :key="2" class="justify-center" v-if="editMode">
                <v-btn @click="updateParam">Применить</v-btn>
            </v-card-actions>
        </v-slide-x-transition>
    </v-card>
</template>
<script>
export default {
    props: {
        tip: String,
        param: Number,
        min: Number,
        max: Number
    },
    data: () => ({
        newValue: null,
        editMode: false
    }),
    mounted() {},
    methods: {
        updateParam() {
            if (this.newValue !== this.param)
                this.$emit("change", this.newValue);
            this.editMode = false;
        },
        changeParam() {
            this.newValue = this.param;
            this.editMode = true;
        },
        resetParam() {
            this.$emit("reset");
        }
    }
};
</script>
