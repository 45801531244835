<template>
    <v-row justify="center">
        <v-col lg="8" sm="12">
            <v-row justify="end" no-gutters>
                <v-btn @click="isNozoExpanded = !isNozoExpanded">
                    <v-icon left>mdi-shuffle</v-icon>
                    {{
                        isNozoExpanded
                            ? "Вывести одним списком"
                            : "Разбить по нозологиям"
                    }}
                </v-btn>
            </v-row>
            <v-expand-transition>
                <mg-nozo-results v-if="isNozoExpanded" :results="drugs" />
                <mg-flat-results v-else :results="flatDrugs" />
            </v-expand-transition>
            <v-row justify="center">
                <v-btn @click="getExcel">Выгрузка Excel</v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { DRUGINFO_GET } from "@/store/actions/project";
import MgNozoResults from "./MGResults/MGNozo.vue";
import MgFlatResults from "./MGResults/MGFlat.vue";

export default {
    components: {
        MgNozoResults,
        MgFlatResults
    },
    data: () => ({
        isNozoExpanded: true
    }),
    mounted() {
        if (!this.drugs) {
            this.$store.dispatch(DRUGINFO_GET);
        }
    },
    computed: {
        drugs() {
            return this.$store.state.project.drugInfo;
        },
        flatDrugs() {
            const res = [];
            this.drugs.forEach(nozo => {
                if (!nozo.drugs) return;
                nozo.drugs.forEach(drug => {
                    const eDrug = res.find(x => x.drug_id === drug.drug_id);
                    if (!eDrug) {
                        res.push({ ...drug });
                    } else {
                        eDrug.size = Number(eDrug.size);
                        eDrug.size += Number(drug.size);
                    }
                });
            });
            return res;
        }
    },
    methods: {
        getExcel() {
            this.$store.dispatch("loading", true);
            if (!this.isNozoExpanded) this.getFlatExcel();
            else this.getNozoExcel();
        },
        getNozoExcel() {
            this.axios
                .get(
                    `/api/projects/${this.$route.params.id}/drugs-excel-nozo`,
                    { responseType: "blob" }
                )
                .then(resp => {
                    const blob = new Blob([resp.data]);
                    const filename = resp.headers["content-disposition"]
                        .split("filename=")[1]
                        .split(";")[0];
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    this.$store.dispatch("loading", false);
                });
        },
        getFlatExcel() {
            this.axios
                .get(
                    `/api/projects/${this.$route.params.id}/drugs-excel-flat`,
                    { responseType: "blob" }
                )
                .then(resp => {
                    const blob = new Blob([resp.data]);
                    const filename = resp.headers["content-disposition"]
                        .split("filename=")[1]
                        .split(";")[0];
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    this.$store.dispatch("loading", false);
                });
        }
    }
};
</script>
