<template>
    <v-row justify="center">
        <pack-prices :packBase="packBase" :show="showPrices" />
        <scheme-list
            :schemeBase="ksg"
            :value="showSchemes"
            @close="showSchemes = false"
        />
        <v-col cols="12" lg="8">
            <v-row justify="space-between" align="center" no-gutters>
                <v-col cols="4" xl="2">
                    <v-btn color="success" @click="showPrices = true">
                        <v-icon left>mdi-shuffle</v-icon>
                        Справочник цен
                    </v-btn>
                </v-col>
                <v-col cols="8" xl="5">
                    <v-row justify="end" no-gutters>
                        <v-card class="px-2">
                            <v-row
                                align="center"
                                class="flex-nowrap"
                                no-gutters
                            >
                                <v-icon large left
                                    >mdi-cash-usd-outline
                                </v-icon>
                                <b class="title">
                                    Амбулаторная медицинская помощь
                                </b>
                            </v-row>
                        </v-card>
                    </v-row>
                </v-col>
                <v-col
                    class="text-center text-xl-right py-xl-0 pt-2"
                    cols="12"
                    xl="5"
                >
                    <v-btn @click="showSchemes = true">
                        <v-icon left
                            >mdi-clipboard-text-multiple-outline
                        </v-icon>
                        Добавить схемы для Амбулаторной помощи
                    </v-btn>
                </v-col>
            </v-row>
            <ksg-budget :proceed="proceed" :total-schemes="totalSchemes" />
            <v-expand-transition group>
                <ksg-settings
                    v-if="ksg"
                    :ksg="listedSchemes"
                    @reset="resetDefaults"
                />
            </v-expand-transition>
        </v-col>
    </v-row>
</template>
<script>
import { KSGPROJECT_SET } from "@/store/actions/project";
import PackPrices from "../AmountResults/PackResults/PackPrices/PackPrices.vue";
import KsgSettings from "./KsgAmbulatory/KsgSettings.vue";
import SchemeList from "./KsgAmbulatory/SchemeList.vue";
import KsgBudget from "@/components/ProjectResults/KsgResults/KsgAmbulatory/KsgBudget";

export default {
    components: {
        KsgBudget,
        PackPrices,
        KsgSettings,
        SchemeList
    },
    data: () => ({
        ambulatoryInfo: null,
        showPrices: false,
        showSchemes: false,
        packBase: null,
        ksg: null,
        enterKsgPrice: false
    }),
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/projects/${this.$route.params.id}/ksg`)
                .then(resp => {
                    this.ambulatoryInfo = {
                        totalPrice: this.$store.state.project.ksgProject
                            .total_price,
                        ksg: resp.data,
                        packInfo: this.$store.state.project.packInfo
                    };
                    this.packBase = this.ambulatoryInfo.packInfo.packBase;
                    this.ksg = this.makeKsg(this.ambulatoryInfo.ksg);
                    this.$store.dispatch("loading", false);
                });
        },
        makeKsg(ksg) {
            const res = [];
            ksg.forEach(scheme => {
                res.push(scheme);
                res[res.length - 1].drugs.forEach((drug, di) => {
                    drug.packs.forEach((pack, pi) => {
                        res[res.length - 1].drugs[di].packs[
                            pi
                        ].prices = this.packBase.find(
                            x => x.id === pack.id
                        ).prices;
                    });
                });
            });
            return res;
        },
        proceed() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/projects/${this.$route.params.id}/ksg/proceed`)
                .then(resp => {
                    this.$store.dispatch(KSGPROJECT_SET, resp.data);
                    this.$store.dispatch("loading", false);
                });
        },
        resetDefaults() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/projects/${this.$route.params.id}/ksg-reset`)
                .then(() => {
                    this.getData();
                });
        }
    },
    computed: {
        listedSchemes() {
            return this.ksg?.filter(x => x.listed) || null;
        },
        totalSchemes() {
            return (
                this.ksg
                    ?.filter(x => x.listed && x.using)
                    .reduce((a, b) => a + b.totalPrice, 0) || 0
            );
        }
    },
    watch: {
        listedSchemes(newVal, oldVal) {
            if (oldVal === null) return;

            if (newVal.length > oldVal.length) {
                const newScheme = newVal.filter(x => !oldVal.includes(x))[0];
                this.axios.get(
                    `/api/projects/${this.$route.params.id}/ksg/schemes/add/${newScheme.id}`
                );
            } else if (newVal.length < oldVal.length) {
                const oldScheme = oldVal.filter(x => !newVal.includes(x))[0];
                this.axios.get(
                    `/api/projects/${this.$route.params.id}/ksg/schemes/remove/${oldScheme.id}`
                );
                this.axios.post(
                    `/api/projects/${this.$route.params.id}/ksg/schemes`,
                    {
                        scheme_id: oldScheme.id,
                        using: false
                    }
                );
            }
        }
    }
};
</script>
