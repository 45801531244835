<template>
    <v-col>
        <v-question-chooser
            :nozologies="project.nozologies"
            :project="project"
            @next="next"
            v-if="project.project_status.id === 1"
        />
        <v-scheme-chooser
            :project="project"
            @next="next"
            v-if="project.project_status.id === 2"
        />
    </v-col>
</template>

<script>
import { PROJECT_SET } from "@/store/actions/project";
import VQuestionChooser from "../../../components/ProjectSettings/PinkyTree.vue";
import VSchemeChooser from "../../../components/ProjectSettings/Schemes.vue";

export default {
    components: {
        VQuestionChooser,
        VSchemeChooser
    },
    mounted() {
        this.$store.dispatch("loading", false);
    },
    methods: {
        next(proj) {
            this.$store.dispatch(PROJECT_SET, proj);
        },
        proceed() {
            this.$parent.updateProject();
        }
    },
    computed: {
        project() {
            return this.$store.state.project.project;
        }
    }
};
</script>
