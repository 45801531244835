<template>
    <v-row class="mt-2" justify="center" no-gutters>
        <v-slide-x-transition leave-absolute>
            <v-card v-if="!changeMode" class="text-center" min-height="110px">
                <v-card-text>
                    <v-row class="text--primary" no-gutters>
                        <v-col>
                            <v-row class="text-no-wrap mx-2" justify="center"
                                >Бюджет АМП:
                            </v-row>
                            <v-row justify="center" no-gutters>
                                <b>{{ priceNormalized(totalPrice) }}</b>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row class="text-no-wrap mx-2" justify="center"
                                >Схем в АМП на:
                            </v-row>
                            <v-row justify="center" no-gutters>
                                <b>{{ priceNormalized(totalSchemes) }}</b>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row class="text-no-wrap mx-2" justify="center"
                                >Осталось распределить:
                            </v-row>
                            <v-row justify="center" no-gutters>
                                <b
                                    :class="{
                                        red: totalSchemes > totalPrice
                                    }"
                                >
                                    {{
                                        priceNormalized(
                                            totalPrice - totalSchemes
                                        )
                                    }}
                                </b>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="space-between" no-gutters>
                        <v-btn @click="changeMode = true">
                            Изменить
                        </v-btn>
                        <v-spacer class="mx-2" />
                        <v-btn
                            :disabled="totalSchemes > totalPrice"
                            color="success"
                            @click="proceed"
                        >
                            Утвердить перечень схем для амбулаторной помощи
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-slide-x-transition>
        <v-slide-x-transition leave-absolute>
            <v-card v-if="changeMode" min-height="110px">
                <v-card-subtitle
                    class="px-2 py-0 text-center
                                text--primary subtitle-1 font-weight-bold"
                >
                    Введите свой бюджет:
                </v-card-subtitle>
                <v-card-text class="pb-2">
                    <v-row class="mt-0" justify="space-between" no-gutters>
                        <v-text-field
                            v-model="newPrice"
                            :max="max"
                            append-icon="mdi-currency-rub"
                            dense
                            hide-details
                            min="0"
                            rounded
                            single-line
                            solo
                            step="0.01"
                            type="number"
                            @keypress="validate"
                        >
                        </v-text-field>
                    </v-row>
                    <v-row class="mt-2" justify="center" no-gutters>
                        <v-btn color="primary" @click="setTotalPrice"
                            >Утвердить
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-slide-x-transition>
    </v-row>
</template>
<script>
export default {
    name: "ksg-budget",
    props: {
        proceed: {},
        totalSchemes: Number
    },
    data: () => ({
        changeMode: false,
        max: 9999999999999.99,
        newPrice: 0
    }),
    mounted() {
        this.newPrice = this.totalPrice;
    },
    methods: {
        setTotalPrice() {
            this.$store.dispatch("loading", true);
            this.changeMode = false;
            this.axios
                .post(`/api/projects/${this.$route.params.id}/ksg/price`, {
                    total_price: this.newPrice
                })
                .then(() => {
                    this.$store.state.project.ksgProject.total_price = this.newPrice;
                    this.$store.dispatch("loading", false);
                });
        },
        validate(event) {
            const val = Number(String(this.totalPrice) + event.key);
            if (val > this.max) {
                event.preventDefault();
                this.newPrice = this.max;
            }
        }
    },
    computed: {
        totalPrice() {
            return this.$store.state.project.ksgProject.total_price;
        }
    }
};
</script>
