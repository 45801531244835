<template>
    <td>
        <v-text-field
            dense
            hide-details
            single-line
            step="1"
            :max="max"
            min="0"
            suffix="шт."
            v-model="value.amount"
            @keypress="validate($event)"
            type="number"
        />
        <v-slider
            class="mt-2"
            step="1"
            v-model="value.amount"
            :max="max"
            dense
            hide-details
        ></v-slider>
    </td>
</template>
<script>
export default {
    props: ["value", "rest"],
    data: () => ({
        rules: {
            max: value => value <= this.max || this.max
        }
    }),
    methods: {
        validate(event) {
            const val = Number(String(this.value.amount) + event.key);
            if (val > this.max) {
                event.preventDefault();
                this.value.amount = this.max;
            }
        }
    },
    computed: {
        max() {
            return Math.max(
                Math.ceil(this.rest / this.value.size) + this.value.amount,
                this.value.amount
            );
        }
    }
};
</script>
