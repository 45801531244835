<template>
    <v-dialog
        :value="value"
        width="unset"
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
    >
        <v-card height="100%">
            <v-toolbar>
                <v-toolbar-title>Настройки КСГ</v-toolbar-title>
                <v-spacer />
                <v-btn fab icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
                <v-card outlined raised>
                    <v-card-title class="justify-center pa-2">
                        <v-row class="mx-2" justify="center" no-gutters>
                            <v-col class="text-center" cols="12">
                                Общие параметры системы оплаты
                            </v-col>
                            <v-col lg="4" sm="8">
                                <v-autocomplete
                                    :items="regionBase"
                                    :value="projectInfo.ksg_reference_id"
                                    dense
                                    hide-details
                                    item-text="name"
                                    item-value="id"
                                    label="Выберите область"
                                    shaped
                                    solo
                                    @change="updateRegion"
                                />
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="px-0">
                        <v-row
                            justify="space-between"
                            no-gutters
                            class="flex-lg-nowrap"
                        >
                            <v-card class="mx-4" outlined>
                                <v-card-title class="justify-center">
                                    Дневной стационар
                                </v-card-title>
                                <v-card-text class="px-0">
                                    <v-row
                                        justify="space-between"
                                        no-gutters
                                        class="flex-nowrap"
                                    >
                                        <ksg-parameter
                                            :max="2"
                                            :min="0.001"
                                            :param="Number(projectInfo.ds_skus)"
                                            tip="Средневзвешенный коэффициент уровня стационара"
                                            @change="updateDsSkus"
                                            @reset="resetDsSkus"
                                        >
                                            СКУС:
                                        </ksg-parameter>
                                        <ksg-parameter
                                            :max="99999.99"
                                            :min="0.01"
                                            :param="Number(projectInfo.ds_bs)"
                                            tip="Базовая ставка"
                                            @change="updateDsBs"
                                            @reset="resetDsBs"
                                        >
                                            БС:
                                        </ksg-parameter>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card class="mx-4" outlined>
                                <v-card-title class="justify-center"
                                    >КД
                                </v-card-title>

                                <v-card-text class="px-0">
                                    <ksg-parameter
                                        :max="9.999"
                                        :min="0.001"
                                        :param="Number(projectInfo.kd)"
                                        tip="Коэффициент дифференциации"
                                        @change="updateKd"
                                        @reset="resetKd"
                                        >КД:
                                    </ksg-parameter>
                                </v-card-text>
                            </v-card>
                            <v-card class="mx-4" outlined>
                                <v-card-title class="justify-center"
                                    >Круглосуточный стационар
                                </v-card-title>
                                <v-card-text class="px-0">
                                    <v-row
                                        justify="space-between"
                                        no-gutters
                                        class="flex-nowrap"
                                    >
                                        <ksg-parameter
                                            :max="2"
                                            :min="0.001"
                                            :param="Number(projectInfo.ks_skus)"
                                            tip="Средневзвешенный коэффициент уровня стационара"
                                            @change="updateKsSkus"
                                            @reset="resetKsSkus"
                                        >
                                            СКУС:
                                        </ksg-parameter>
                                        <ksg-parameter
                                            :max="99999.99"
                                            :min="0.01"
                                            :param="Number(projectInfo.ks_bs)"
                                            tip="Базовая ставка"
                                            @change="updateKsBs"
                                            @reset="resetKsBs"
                                        >
                                            БС:
                                        </ksg-parameter>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mx-auto mt-2 pt-4">
                    <v-row class="title" justify="center"
                        >Управленческие коэффициенты КСГ (КУ), применяемые в
                        расчете
                    </v-row>
                    <v-row>
                        <v-col class="px-8">
                            <ksg-ku
                                v-for="ksg in ksgBase.filter(
                                    x => x.ksg_type_id === 1
                                )"
                                :key="ksg.id"
                                :ksg="ksg"
                                @load="
                                    e => {
                                        this.$store.dispatch('loading', e);
                                    }
                                "
                            />
                        </v-col>
                        <v-divider vertical />
                        <v-col class="px-8">
                            <ksg-ku
                                v-for="ksg in ksgBase.filter(
                                    x => x.ksg_type_id === 2
                                )"
                                :key="ksg.id"
                                :ksg="ksg"
                                @load="
                                    e => {
                                        this.$store.dispatch('loading', e);
                                    }
                                "
                            />
                        </v-col>
                    </v-row>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import KsgParameter from "@/components/ProjectResults/KsgResults/KsgStationary/KsgParameter.vue";
import KsgKu from "@/components/ProjectResults/KsgResults/KsgStationary/KsgKu.vue";

export default {
    components: {
        KsgKu,
        KsgParameter
    },
    props: ["value", "regionBase", "ksgBase", "projectInfo"],
    methods: {
        updateDsBs(newVal) {
            this.$store.dispatch("loading", true);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/ksg/ds-bs`, {
                    ds_bs: newVal
                })
                .then(resp => {
                    this.projectInfo.ds_bs = resp.data.ds_bs;
                    this.$store.dispatch("loading", false);
                })
                .catch(() => {
                    this.$store.dispatch("loading", false);
                });
        },
        resetDsBs() {
            this.updateDsBs(this.region.ds_bs);
        },
        updateKsBs(newVal) {
            this.$store.dispatch("loading", true);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/ksg/ks-bs`, {
                    ks_bs: newVal
                })
                .then(resp => {
                    this.projectInfo.ks_bs = resp.data.ks_bs;
                    this.$store.dispatch("loading", false);
                })
                .catch(() => {
                    this.$store.dispatch("loading", false);
                });
        },
        resetKsBs() {
            this.updateKsBs(this.region.ks_bs);
        },
        updateDsSkus(newVal) {
            this.$store.dispatch("loading", true);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/ksg/ds-skus`, {
                    ds_skus: newVal
                })
                .then(resp => {
                    this.projectInfo.ds_skus = resp.data.ds_skus;
                    this.$store.dispatch("loading", false);
                })
                .catch(() => {
                    this.$store.dispatch("loading", false);
                });
        },
        resetDsSkus() {
            this.updateDsSkus(this.region.ds_skus);
        },
        updateKsSkus(newVal) {
            this.$store.dispatch("loading", true);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/ksg/ks-skus`, {
                    ks_skus: newVal
                })
                .then(resp => {
                    this.projectInfo.ks_skus = resp.data.ks_skus;
                    this.$store.dispatch("loading", false);
                })
                .catch(() => {
                    this.$store.dispatch("loading", false);
                });
        },
        resetKsSkus() {
            this.updateKsSkus(this.region.ks_skus);
        },
        updateKd(newVal) {
            this.$store.dispatch("loading", true);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/ksg/kd`, {
                    kd: newVal
                })
                .then(resp => {
                    this.projectInfo.kd = resp.data.kd;
                    this.$store.dispatch("loading", false);
                })
                .catch(() => {
                    this.$store.dispatch("loading", false);
                });
        },
        resetKd() {
            this.updateKd(this.region.kd);
        },
        updateRegion(regionId) {
            this.$store.dispatch("loading", true);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/ksg/regions`, {
                    region_id: regionId
                })
                .then(resp => {
                    this.$store.dispatch("loading", false);
                    this.$emit("update", resp.data);
                });
        }
    },
    computed: {
        region() {
            return this.regionBase.find(
                x => x.id === this.projectInfo.ksg_reference_id
            );
        }
    }
};
</script>
