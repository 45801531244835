<template>
    <v-card flat>
        <v-row
            align="center"
            justify="center"
            no-gutters
            style="min-height: 40px;"
        >
            <v-slide-x-transition hide-on-leave>
                <div v-if="!editMode" class="title mx-2 my-2">
                    Региональная надбавка: {{ rat }}%
                </div>
                <v-text-field
                    v-else
                    v-model="newRat"
                    class="mx-2 shrink my-1"
                    dense
                    hide-details
                    outlined
                    step="0.01"
                    type="number"
                />
            </v-slide-x-transition>
            <v-btn v-if="!editMode" small @click="editMode = true"
                >Изменить
            </v-btn>
            <v-btn
                v-else
                class="primary"
                small
                @click="
                    editMode = false;
                    $emit('change-rat', newRat);
                "
                >Применить
            </v-btn>
        </v-row>
    </v-card>
</template>
<script>
export default {
    name: "rat-changer",
    data: () => ({
        editMode: false,
        newRat: 0
    }),
    mounted() {
        this.newRat = this.rat;
    },
    computed: {
        rat() {
            return this.$store.state.project.project.rat;
        }
    }
};
</script>
