<template>
    <v-row justify="center">
        <v-col>
            <v-row no-gutters class="my-1" justify="space-between">
                <v-btn @click="selectAll">
                    <v-checkbox
                        :indeterminate="indeterminate"
                        :input-value="allSelected"
                        class="pa-0 ma-0"
                        dense
                        hide-details
                        readonly
                    ></v-checkbox>
                    {{ this.allSelected ? "Снять выделение" : "Выбрать всё" }}
                </v-btn>
                <v-btn @click="resetDefaults">Сбросить по умолчанию</v-btn>
            </v-row>
            <v-expansion-panels hover>
                <ksg-scheme
                    :key="scheme.id"
                    :scheme="scheme"
                    @priceUpdate="
                        $event => {
                            $set(ksg[i], 'totalPrice', $event);
                        }
                    "
                    v-for="(scheme, i) in ksg"
                />
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>
<script>
import KsgScheme from "./KsgScheme.vue";

export default {
    components: {
        KsgScheme
    },
    props: ["ksg"],
    methods: {
        selectAll() {
            this.$store.dispatch("loading", true);
            if (this.allSelected) {
                this.deselectAll();
            } else {
                const schemesToSelect = this.ksg.filter(x => x.using === false);
                const requests = [];
                schemesToSelect.forEach(scheme => {
                    requests.push(
                        this.axios.post(
                            `/api/projects/${this.$route.params.id}/ksg/schemes`,
                            {
                                scheme_id: scheme.id,
                                using: true
                            }
                        )
                    );
                });
                Promise.all(requests).then(() => {
                    schemesToSelect.forEach((_, i) => {
                        schemesToSelect[i].using = true;
                    });
                    this.$store.dispatch("loading", false);
                });
            }
        },
        deselectAll() {
            const requests = [];
            this.ksg.forEach(scheme => {
                requests.push(
                    this.axios.post(
                        `/api/projects/${this.$route.params.id}/ksg/schemes`,
                        {
                            scheme_id: scheme.id,
                            using: false
                        }
                    )
                );
            });
            Promise.all(requests).then(() => {
                this.ksg.forEach((_, i) => {
                    this.ksg[i].using = false;
                });
                this.$store.dispatch("loading", false);
            });
        },
        resetDefaults() {
            this.$emit("reset");
        }
    },
    computed: {
        allSelected() {
            return (
                this.ksg.filter(x => x.using === true).length ===
                this.ksg.length
            );
        },
        someSelected() {
            return this.ksg.some(x => x.using === true);
        },
        indeterminate() {
            return this.someSelected && !this.allSelected;
        }
    }
};
</script>
