<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="px-4">
            <template v-slot:actions>
                <v-tooltip open-delay="250" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="primary"
                            >mdi-arrow-down</v-icon
                        >
                    </template>
                    <span>Посмотреть перечень лекарственных препаратов</span>
                </v-tooltip>
            </template>

            <v-row align="center" no-gutters>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="scheme.using"
                            icon
                            small
                            @click.native="unlistScheme($event)"
                        >
                            <v-icon color="error"
                                >mdi-close-circle-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Убрать схему из списка</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            small
                            @click.native="switchScheme($event)"
                        >
                            <v-icon :color="scheme.using ? 'primary' : ''"
                                >mdi-check-circle-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Применять схему амбулаторно</span>
                </v-tooltip>
                <v-icon v-if="scheme.scheme_type === 'tablet'" left
                    >mdi-pill</v-icon
                >
                <v-icon v-else-if="scheme.scheme_type === 'inject'" left
                    >mdi-needle</v-icon
                >
                <v-tooltip open-delay="500" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-col v-bind="attrs" v-on="on">
                            <v-row
                                align="center"
                                justify="space-between"
                                no-gutters
                            >
                                <v-col class="mr-2">
                                    {{ scheme.description }}
                                </v-col>
                                <p class="my-0 mx-2">
                                    {{ priceNormalized(scheme.totalPrice) }}
                                </p>
                            </v-row>
                        </v-col>
                    </template>
                    <span>
                        Используется в:
                        <span
                            v-for="(nozo, i) in scheme.nozologies"
                            :key="nozo"
                        >
                            {{
                                nozo +
                                    (i === scheme.nozologies.length - 1
                                        ? ""
                                        : ",&nbsp;")
                            }}
                        </span>
                    </span>
                </v-tooltip>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-expansion-panels class="mt-4">
                <ksg-drug
                    v-for="(drug, i) in scheme.drugs"
                    :key="drug.id"
                    :drug="drug"
                    @priceUpdate="
                        $event => {
                            $set(scheme.drugs[i], 'totalPrice', $event);
                            recalculatePrice();
                        }
                    "
                />
            </v-expansion-panels>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
import KsgDrug from "./KsgDrug.vue";

export default {
    components: {
        KsgDrug
    },
    props: ["scheme"],
    methods: {
        recalculatePrice() {
            const res = this.scheme.drugs.reduce(
                (a, b) => a + Number(b.totalPrice),
                0
            );
            this.$emit("priceUpdate", res);
        },
        switchScheme(e) {
            this.scheme.using = !this.scheme.using;
            this.axios.post(
                `/api/projects/${this.$route.params.id}/ksg/schemes`,
                {
                    scheme_id: this.scheme.id,
                    using: this.scheme.using
                }
            );
            e.cancelBubble = true;
        },
        unlistScheme(e) {
            this.scheme.using = false;
            this.scheme.listed = false;
            e.cancelBubble = true;
        }
    }
};
</script>
