<template>
    <v-col>
        <v-row>
            <v-tabs
                :value="displayGroup - 1"
                centered
                grow
                show-arrows
                @change="$event => changeDisplayGroup($event + 1)"
            >
                <v-tab :key="1">
                    Расчёт потребности в противоопухолевых препаратах
                    <v-icon right>mdi-pill</v-icon>
                </v-tab>
                <v-tab :key="2">
                    Каналы финансирования противоопухолевой лекарственной
                    терапии
                    <v-icon right>mdi-cash</v-icon>
                </v-tab>
            </v-tabs>
        </v-row>
        <v-container fluid>
            <v-amount-results v-if="displayGroup === 1" />
            <v-ksg-results v-else />
        </v-container>
    </v-col>
</template>
<script>
import VAmountResults from "@/components/ProjectResults/AmountResults.vue";
import VKsgResults from "@/components/ProjectResults/KsgResults.vue";
import { PROJECT_SET } from "@/store/actions/project";

export default {
    components: {
        VAmountResults,
        VKsgResults
    },
    data: () => ({
        projectInfo: {}
    }),
    computed: {
        displayForm() {
            return this.project ? this.project.project_display_form_id : null;
        },
        displayGroup() {
            return this.displayForm === 3 ? 2 : 1;
        },
        project() {
            return this.$store.state.project.project;
        }
    },
    methods: {
        changeDisplayGroup(displayGroup) {
            this.$store.dispatch("loading", true);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/form`, {
                    display_form: displayGroup === 2 ? 3 : 1
                })
                .then(resp => {
                    this.$store.dispatch(PROJECT_SET, resp.data);
                    this.$store.dispatch("loading", false);
                });
        }
    }
};
</script>
