<template>
    <v-row no-gutters>
        <v-col class="text-center" cols="12">
            <v-btn class="mt-3" color="primary" @click="changePacks">
                Перераспределить
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-simple-table class="mb-4 mt-2">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th
                                v-for="s in [
                                    'Торговое наименование',
                                    'Лекарственная форма',
                                    'Упаковка (полная)'
                                ]"
                                :key="s"
                                class="text-left"
                                v-text="s"
                            />
                            <th class="text-right">Цена</th>
                            <th class="text-right">Количество</th>
                            <th class="text-right">Стоимость</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="pack in drug.packs" :key="pack.id">
                            <td
                                v-for="s in [
                                    pack.name,
                                    pack.description,
                                    pack.form
                                ]"
                                :key="s"
                                v-text="s"
                            />
                            <td class="text-no-wrap">
                                <span
                                    :class="{
                                        'lime lighten-3':
                                            pack.prices.price !==
                                            pack.prices.defaultPrice
                                    }"
                                    >{{
                                        priceNormalized(getPackPrice(pack))
                                    }}</span
                                >
                            </td>
                            <td align="right" class="text-no-wrap">
                                {{ amountNormalized(pack.amount) }}
                            </td>
                            <td align="right" class="text-no-wrap">
                                {{
                                    priceNormalized(
                                        getPackPrice(pack) * pack.amount
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td align="right" colspan="6">
                                Итого:
                                {{ priceNormalized(totalPrice) }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<script>
import { PACKS_SET } from "@/store/actions/packs";

export default {
    props: ["drug"],
    methods: {
        changePacks() {
            this.$store.dispatch(PACKS_SET, this.drug);
        }
    },
    computed: {
        totalPrice() {
            return this.drug.packs.reduce(
                (cnt, o) => cnt + o.prices.price * o.amount,
                0
            );
        }
    }
};
</script>
