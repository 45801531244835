<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="px-4">
            <v-row no-gutters align="center">
                <v-col> {{ drug.name }}</v-col>
                <p class="my-0 mx-2">{{ priceNormalized(drug.totalPrice) }}</p>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <ksg-packs @priceUpdate="recalculatePrice" :packs="drug.packs" />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
import KsgPacks from "./KsgPacks.vue";

export default {
    components: {
        KsgPacks
    },
    props: ["drug"],
    methods: {
        recalculatePrice() {
            const res = this.drug.packs.reduce(
                (a, b) => a + this.getPackPrice(b) * b.amount,
                0
            );
            this.$emit("priceUpdate", res);
        }
    }
};
</script>
