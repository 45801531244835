<template>
    <v-row>
        <v-tabs
            v-model="choosenKey"
            center-active
            centered
            grow
            icons-and-text
            show-arrows
        >
            <v-tab
                v-for="(q, nozo, i) in questionList"
                :key="i"
                style="max-width: 600px;"
            >
                <v-icon :color="nozoPassed(q) ? 'primary' : 'grey lighten-1'">
                    {{ "mdi-check-circle" }}
                </v-icon>
                <span class="shrink">{{ i + 1 }}. {{ nozo }}</span>
            </v-tab>
        </v-tabs>
        <v-row>
            <v-col class="text-center" cols="12">
                <div>
                    Распределено нозологий:
                    <b>
                        {{ amountPassed }}
                        из
                        {{ Object.values(questionList).length }}
                    </b>
                </div>
                <div class="font-weight-bold headline">{{ choosenNozo }}</div>
            </v-col>
        </v-row>
        <v-col cols="12">
            <v-row justify="center">
                <v-col>
                    <nozo-watcher
                        v-if="choosenNozo !== null"
                        :key="choosenNozo"
                        :baseQuestionList="questionList[choosenNozo]"
                        @next="nextNozo"
                    />
                </v-col>
            </v-row>
            <v-row v-if="canCalculate" justify="center">
                <v-btn class="ma-2" color="warning" @click="proceed">
                    Окончательно утвердить распределение
                </v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import NozoWatcher from "./PinkyTree/NozoWatcher.vue";

export default {
    props: ["nozologies"],
    components: {
        NozoWatcher
    },
    data: () => ({
        questionList: [],
        choosenKey: 0
    }),
    mounted() {
        this.axios
            .get(`/api/projects/${this.$route.params.id}/questions`)
            .then(resp => {
                this.questionList = resp.data;
                this.$store.dispatch("loading", false);
            });
    },
    computed: {
        nozoList() {
            return Object.keys(this.questionList);
        },
        amountPassed() {
            return Object.values(this.questionList).reduce(
                (a, b) => a + b.every(q => q.passed),
                0
            );
        },
        choosenNozo() {
            return this.nozoList[this.choosenKey] ?? null;
        },
        canCalculate() {
            let canCalculate = true;
            Object.values(this.questionList).forEach(qlist => {
                if (qlist.some(q => !q.passed)) {
                    canCalculate = false;
                }
            });
            return canCalculate;
        }
    },
    methods: {
        nextNozo() {
            if (this.nozoList.length - 1 > this.choosenKey)
                this.choosenKey += 1;
            else this.choosenKey = 0;
        },
        nozoPassed(qlist) {
            return qlist.every(q => q.passed);
        },
        proceed() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/projects/${this.$route.params.id}/proceed`)
                .then(resp => {
                    this.$emit("next", resp.data);
                });
        }
    }
};
</script>
