<template>
    <v-expansion-panels hover>
        <ksg
            v-for="ksg in ksgBase"
            :key="ksg.id"
            :ksg="ksg"
            :projectInfo="projectInfo"
            :schemes="getKsgSchemes(ksg)"
            :type="type"
        />
    </v-expansion-panels>
</template>

<script>
import Ksg from "@/components/ProjectResults/KsgResults/KsgFinal/Stationary/Ksg.vue";

export default {
    methods: {
        getKsgSchemes(ksg) {
            return this.schemes.filter(x => {
                if (this.type === "ds") return x.scheme.ds_ksg_id === ksg.id;
                return x.scheme.ks_ksg_id === ksg.id;
            });
        }
    },
    components: {
        Ksg
    },
    props: {
        ksgBase: Array,
        schemes: Array,
        projectInfo: Object,
        type: String
    }
};
</script>
