var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary"},on:{"click":_vm.changePacks}},[_vm._v(" Перераспределить ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"mb-4 mt-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_vm._l(([
                                'Торговое наименование',
                                'Лекарственная форма',
                                'Упаковка (полная)'
                            ]),function(s){return _c('th',{key:s,staticClass:"text-left",domProps:{"textContent":_vm._s(s)}})}),_c('th',{staticClass:"text-right"},[_vm._v("Цена")]),_c('th',{staticClass:"text-right"},[_vm._v("Количество")]),_c('th',{staticClass:"text-right"},[_vm._v("Стоимость")])],2)]),_c('tbody',[_vm._l((_vm.drug.packs),function(pack){return _c('tr',{key:pack.id},[_vm._l(([
                                pack.name,
                                pack.description,
                                pack.form
                            ]),function(s){return _c('td',{key:s,domProps:{"textContent":_vm._s(s)}})}),_c('td',{staticClass:"text-no-wrap"},[_c('span',{class:{
                                    'lime lighten-3':
                                        pack.prices.price !==
                                        pack.prices.defaultPrice
                                }},[_vm._v(_vm._s(_vm.priceNormalized(_vm.getPackPrice(pack))))])]),_c('td',{staticClass:"text-no-wrap",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.amountNormalized(pack.amount))+" ")]),_c('td',{staticClass:"text-no-wrap",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.priceNormalized( _vm.getPackPrice(pack) * pack.amount ))+" ")])],2)}),_c('tr',[_c('td',{attrs:{"align":"right","colspan":"6"}},[_vm._v(" Итого: "+_vm._s(_vm.priceNormalized(_vm.totalPrice))+" ")])])],2)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }