<template>
    <v-container fluid>
        <ksg-region v-if="ksgProjectStatus === 1" />
        <ksg-ambulatory v-else-if="ksgProjectStatus === 2" />
        <ksg-stationary v-else-if="ksgProjectStatus === 3" />
        <ksg-final v-else-if="ksgProjectStatus === 4" />
    </v-container>
</template>
<script>
import KsgAmbulatory from "@/components/ProjectResults/KsgResults/KsgAmbulatory.vue";
import KsgStationary from "@/components/ProjectResults/KsgResults/KsgStationary.vue";
import KsgRegion from "@/components/ProjectResults/KsgResults/KsgRegion.vue";
import KsgFinal from "@/components/ProjectResults/KsgResults/KsgFinal.vue";
import { KSGPROJECT_GET, PACKINFO_GET } from "@/store/actions/project";

export default {
    components: {
        KsgFinal,
        KsgRegion,
        KsgAmbulatory,
        KsgStationary
    },
    data: () => ({
        summaryInfo: null
    }),
    mounted() {
        if (!this.packInfo) {
            this.$store.dispatch(PACKINFO_GET).then(() => {
                this.makeKsgBases();
            });
        } else {
            this.makeKsgBases();
        }
    },
    computed: {
        packInfo() {
            return this.$store.state.project.packInfo;
        },
        ksgProject() {
            return this.$store.state.project.ksgProject;
        },
        ksgProjectStatus() {
            return this.ksgProject?.ksg_project_status_id;
        }
    },
    methods: {
        makeKsgBases() {
            if (!this.ksgProject) {
                this.$store.dispatch(KSGPROJECT_GET);
            }
        },
        getSummaryInfo() {
            const regionBase = this.axios.get(
                `/api/projects/${this.$route.params.id}/ksg/regions`
            );
            const ksgBase = this.axios.get(
                `/api/projects/${this.$route.params.id}/ksg-base`
            );
            const schemeBase = this.axios.get(
                `/api/projects/${this.$route.params.id}/ksg-scheme-base`
            );
            const ksg = this.axios.get(
                `/api/projects/${this.$route.params.id}/ksg`
            );
            Promise.all([regionBase, ksgBase, schemeBase, ksg]).then(
                results => {
                    this.summaryInfo = {
                        projectInfo: this.projectInfo,
                        regionBase: results[0].data,
                        ksgBase: results[1].data,
                        schemeBase: results[2].data,
                        ksg: results[3].data
                    };
                    this.$store.dispatch("loading", false);
                }
            );
        },
        getKsgProjectInfo() {
            switch (this.projectInfo.ksg_project_status_id) {
                case 4: {
                    this.getSummaryInfo();
                    break;
                }
                default:
                    break;
            }
        }
    }
};
</script>
