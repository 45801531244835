<template>
    <v-container fluid>
        <v-row v-if="project">
            <project-results v-if="project.done" />
            <project-settings v-else />
        </v-row>
    </v-container>
</template>

<script>
import { PROJECT_SET } from "@/store/actions/project";
import ProjectSettings from "./Project/ProjectSettings.vue";
import ProjectResults from "./Project/ProjectResults.vue";

export default {
    components: {
        ProjectSettings,
        ProjectResults
    },
    mounted() {
        this.updateProject();
    },
    methods: {
        updateProject() {
            this.axios
                .get(`/api/projects/${this.$route.params.id}`)
                .then(resp => {
                    this.$store.dispatch(PROJECT_SET, resp.data);
                    this.$store.dispatch("loading", false);
                });
        }
    },
    computed: {
        project() {
            return this.$store.state.project.project;
        }
    }
};
</script>
