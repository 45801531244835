<style>
.list__tile__sub-title,
.list__tile__title {
    white-space: normal;
}
</style>

<template>
    <v-row justify="center" no-gutters>
        <v-expansion-panels>
            <v-expansion-panel v-for="nozo in results" :key="nozo.id">
                <v-expansion-panel-header>
                    {{ nozo.full_name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="nozo.drugs"
                            class="shrink"
                            dense
                            disable-filtering
                            disable-pagination
                            hide-default-footer
                            item-key="id"
                            show-expand
                            @click:row="onRowClick"
                        >
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <v-packs :drug="item" />
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
</template>
<script>
import VPacks from "./Packs.vue";

export default {
    components: {
        VPacks
    },
    props: ["results"],
    data: () => ({
        headers: [
            {
                text: "АТХ",
                align: "start",
                sortable: false,
                value: "code"
            },
            { text: "МНН", value: "name" },
            { text: "Путь введения", value: "inject_type" }
        ]
    }),
    methods: {
        onRowClick(drug, row) {
            row.expand(!row.isExpanded);
        }
    }
};
</script>
