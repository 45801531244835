<template>
    <v-expansion-panel>
        <v-expansion-panel-header
            >{{ nozo.full_name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row align="center" justify="space-between" no-gutters>
                <v-btn @click="selectAll">Выбрать всё</v-btn>
                <v-text-field
                    v-model="search"
                    class="col-sm-4 col-12"
                    dense
                    hide-details
                    label="Поиск по схемам"
                    outlined
                />
                <v-btn @click="deselectAll">Снять всё</v-btn>
            </v-row>
            <v-list>
                <v-list-item-group v-model="choosenSchemes" multiple>
                    <template v-for="scheme in schemes">
                        <v-divider
                            v-show="filteredSchemes.includes(scheme)"
                            :key="scheme.id + 'div'"
                        />
                        <v-list-item
                            v-show="filteredSchemes.includes(scheme)"
                            :key="scheme.id"
                            :value="scheme"
                            active-class="primary--text"
                            @click="switchScheme(scheme)"
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        <b>{{ scheme.name }}:</b>
                                        {{ scheme.description }}
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-checkbox
                                        :input-value="active"
                                        color="primary"
                                        @click="1"
                                    ></v-checkbox>
                                </v-list-item-action>
                            </template>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
export default {
    props: {
        nozo: Object,
        schemes: Array
    },
    data: () => ({
        choosenSchemes: [],
        search: ""
    }),
    mounted() {
        this.choosenSchemes = this.schemes.filter(x => x.using);
    },
    methods: {
        switchScheme(scheme) {
            const sch = this.schemes.find(x => x.id === scheme.id);
            this.axios
                .post(
                    `/api/projects/${this.$route.params.id}/schemes/${
                        !sch.using ? "add" : "remove"
                    }`,
                    {
                        nozo_id: this.nozo.id,
                        scheme_id: sch.id
                    }
                )
                .then(() => {
                    sch.using = !sch.using;
                });
        },
        selectAll() {
            this.$store.dispatch("loading", true);
            if (this.schemes.length === this.choosenSchemes.length) return;

            this.axios
                .post(`/api/projects/${this.$route.params.id}/schemes/addAll`, {
                    nozo_id: this.nozo.id
                })
                .then(() => {
                    this.schemes.forEach((scheme, i) => {
                        this.schemes[i].using = true;
                    });
                    this.choosenSchemes = [...this.schemes];
                    this.$store.dispatch("loading", false);
                });
        },
        deselectAll() {
            this.$store.dispatch("loading", true);
            if (this.choosenSchemes.length === 0) return;

            this.axios
                .post(
                    `/api/projects/${this.$route.params.id}/schemes/removeAll`,
                    {
                        nozo_id: this.nozo.id
                    }
                )
                .then(() => {
                    this.schemes.forEach((scheme, i) => {
                        this.schemes[i].using = false;
                    });
                    this.choosenSchemes = [];
                    this.$store.dispatch("loading", false);
                });
        }
    },
    computed: {
        isValid() {
            return !!this.choosenSchemes.length;
        },
        filteredSchemes() {
            return this.schemes.filter(x => {
                return (
                    x.name.toLowerCase().includes(this.search.toLowerCase()) ||
                    x.description
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                );
            });
        }
    }
};
</script>
