<template>
    <v-dialog
        :value="value"
        max-width="800px"
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
    >
        <v-card height="100%">
            <v-toolbar>
                <v-toolbar-title
                    >Изменение распределения случаев лечения
                    схемы?</v-toolbar-title
                >
                <v-spacer />
                <v-btn fab icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="mt-2 text--primary text-center">
                {{ scheme.scheme.description }}
                <v-row class="mt-2" justify="center"
                    >Всего случаев: {{ scheme.cases.total }}
                </v-row>
                <v-row align="center" justify="center">
                    <v-card width="100px">
                        <v-card-actions class="justify-center">
                            ДС ({{ dsPercent }}%)
                        </v-card-actions>
                        <v-card-actions class="justify-center">
                            <v-text-field
                                v-model="ds"
                                :max="scheme.cases.total"
                                class="pa-0 ma-0"
                                dense
                                hide-details
                                min="0"
                                outlined
                                type="number"
                            />
                        </v-card-actions>
                    </v-card>
                    <v-col>
                        <v-slider
                            v-model="ds"
                            :max="scheme.cases.total"
                            :min="0"
                            class="align-center"
                            hide-details
                        >
                        </v-slider>
                    </v-col>
                    <v-card width="100px">
                        <v-card-actions class="justify-center">
                            КС ({{ ksPercent }}%)
                        </v-card-actions>
                        <v-card-actions class="justify-center">
                            <v-text-field
                                v-model="ks"
                                :max="scheme.cases.total"
                                class="pa-0 ma-0"
                                dense
                                hide-details
                                min="0"
                                outlined
                                type="number"
                            />
                        </v-card-actions>
                    </v-card>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="setSchemeRatio">
                    Подтвердить распределение
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["scheme", "value"],
    data: () => ({
        ds: 0,
        ks: 0
    }),
    mounted() {
        this.ds = this.scheme.cases.ds;
        this.ks = this.scheme.cases.total - this.ds;
    },
    watch: {
        scheme(newVal) {
            this.ds = newVal.cases.ds;
            this.ks = newVal.cases.total - this.ds;
        },
        ds(newVal) {
            this.ks = this.scheme.cases.total - newVal;
        },
        ks(newVal) {
            this.ds = this.scheme.cases.total - newVal;
        }
    },
    methods: {
        setSchemeRatio() {
            this.axios
                .post(
                    `/api/projects/${this.$route.params.id}/ksg/stat-schemes`,
                    {
                        scheme_id: this.scheme.id,
                        ds: this.ds,
                        ks: this.ks
                    }
                )
                .then(resp => {
                    this.scheme.cases.ds = resp.data.ds_cases;
                    this.scheme.cases.ks = resp.data.ks_cases;
                    this.$emit("update", this.scheme);
                    this.$emit("close");
                });
        }
    },
    computed: {
        dsPercent() {
            return Math.round((this.ds / this.scheme.cases.total) * 100);
        },
        ksPercent() {
            return 100 - this.dsPercent;
        }
    }
};
</script>
