<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th
                        class="text-left"
                        v-for="s in [
                            'Торговое наименование',
                            'Лекарственная форма',
                            'Упаковка (полная)'
                        ]"
                        :key="s"
                        v-text="s"
                    />
                    <th class="text-right" width="15%">Цена</th>
                    <th class="text-right" width="10%">Количество</th>
                    <th class="text-right" width="15%">Стоимость</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="pack in packs" :key="pack.id">
                    <td>{{ pack.name }}</td>
                    <td>{{ pack.description }}</td>
                    <td>{{ pack.form }}</td>
                    <td align="right">
                        {{ priceNormalized(getPackPrice(pack)) }}
                    </td>
                    <td align="right">{{ amountNormalized(pack.amount) }}</td>
                    <td align="right">
                        {{ priceNormalized(getPackPrice(pack) * pack.amount) }}
                    </td>
                </tr>
            </tbody>
        </template></v-simple-table
    >
</template>
<script>
export default {
    props: ["packs"],
    mounted() {
        this.$emit("priceUpdate");
    },
    watch: {
        packs: {
            handler() {
                this.$emit("priceUpdate");
            },
            deep: true
        }
    }
};
</script>
