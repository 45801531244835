<template>
    <v-row justify="center">
        <v-col>
            <v-expansion-panels>
                <v-expansion-panel v-for="nozo in results" :key="nozo.id">
                    <v-expansion-panel-header>
                        {{ nozo.full_name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">АТХ</th>
                                        <th class="text-left">МНН</th>
                                        <th class="text-left">Путь введения</th>
                                        <th class="text-right">Количество</th>
                                        <th class="text-center">
                                            Единица измерения
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="drug in nozo.drugs"
                                        :key="drug.id"
                                    >
                                        <td>{{ drug.code }}</td>
                                        <td>{{ drug.name }}</td>
                                        <td>
                                            {{ drug.inject_type }}
                                        </td>
                                        <td align="right">
                                            {{ unitNormalized(drug.size) }}
                                        </td>
                                        <td align="center">
                                            {{ drug.unit_type }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: ["results"]
};
</script>
