<template>
    <v-container class="pa-0" fluid>
        <v-card class="my-2">
            <v-card-text>
                <v-row class="text--primary" no-gutters>
                    <v-col>
                        <v-row class="text-no-wrap mx-2" justify="center"
                            >Бюджет АМП:
                        </v-row>
                        <v-row justify="center">
                            <b>{{ priceNormalized(totalPrice) }}</b>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row class="text-no-wrap mx-2" justify="center">
                            Схем в АМП на:
                        </v-row>
                        <v-row justify="center">
                            <b>{{ priceNormalized(totalSchemes) }}</b>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row class="text-no-wrap mx-2" justify="center"
                            >Свободный бюджет:
                        </v-row>
                        <v-row justify="center">
                            <b :class="{ red: totalSchemes > totalPrice }">
                                {{ priceNormalized(totalPrice - totalSchemes) }}
                            </b>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-expand-transition group>
            <ksg-ambulatory-list :schemes="schemes" />
        </v-expand-transition>
    </v-container>
</template>

<script>
import KsgAmbulatoryList from "@/components/ProjectResults/KsgResults/KsgFinal/Ambulatory/KsgAmbulatoryList.vue";

export default {
    name: "KsgAmbulatory",
    components: { KsgAmbulatoryList },
    data: () => ({
        schemes: []
    }),
    mounted() {
        this.$store.dispatch("loading", true);
        this.axios
            .get(
                `/api/projects/${this.$store.state.project.project.id}/ksg/ambulatory-schemes`
            )
            .then(resp => {
                this.schemes = resp.data;
                this.$store.dispatch("loading", false);
            });
    },
    computed: {
        totalSchemes() {
            return this.schemes.reduce((a, b) => a + b.totalPrice, 0) || 0;
        },
        totalPrice() {
            return this.$store.state.project.ksgProject.total_price;
        }
    }
};
</script>

<style scoped></style>
