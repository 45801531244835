<template>
    <v-card>
        <v-card-subtitle class="body-2 text--secondary text-wrap pa-2">
            >> {{ path }}
        </v-card-subtitle>
        <v-card-title class="title font-weight-bold text-wrap py-0"
            >Распределите: {{ question }}?</v-card-title
        >
        <v-card-text class="py-4">
            <v-row
                v-for="(a, i) in value"
                :key="i"
                align="center"
                justify="space-between"
                no-gutters
            >
                <v-card flat width="140px" class="mx-2">
                    {{ a.answer }}
                </v-card>
                <v-col class="mx-2">
                    <v-slider
                        hide-details
                        step="0.0001"
                        :max="1"
                        v-model="a.ratio"
                        @change="a.ratio = Math.round($event * 100) / 100"
                    >
                        <template v-slot:thumb-label="{ value }">
                            <div class="caption">
                                {{ Math.round(value * 100) }}%
                            </div>
                        </template>
                    </v-slider>
                </v-col>
                <v-card flat min-width="100px" max-width="120px" class="mx-2">
                    <v-text-field
                        reverse
                        outlined
                        type="number"
                        dense
                        hide-details
                        step="0.01"
                        :value="Math.round(a.ratio * 10000) / 100"
                        @input="a.ratio = Math.round($event * 100) / 10000"
                        append-outer-icon="%"
                    >
                    </v-text-field>
                </v-card>
            </v-row>
            <v-row justify="space-between" align="center" class="ma-2">
                <v-btn small @click="setDefault">
                    Сбросить по умолчанию
                </v-btn>
                <v-chip
                    v-if="rest !== 0"
                    label
                    class="display-caption float-right px-2"
                    color="red"
                    text-color="white"
                >
                    Сумма составляет не 100%! Проверьте ваши ответы! ({{
                        Math.round(total * 10000) / 100
                    }}%)
                </v-chip>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ["value", "path", "question"],
    computed: {
        rest() {
            return Math.round((1 - this.total) * 10000) / 100;
        },
        total() {
            let total = 0;
            this.value.forEach(a => {
                total +=
                    Math.round((Number(a.ratio) + Number.EPSILON) * 10000) /
                    10000;
            });
            return total;
        }
    },
    methods: {
        setDefault() {
            this.value.forEach((a, i) => {
                this.value[i].ratio = a.defaultRatio;
            });
        }
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.$emit("update", val);
            }
        },
        total: {
            handler(val) {
                this.$emit("total", val);
            }
        }
    }
};
</script>
