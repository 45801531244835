<template>
    <tr :class="{ 'grey lighten-3': scheme.scheme.ksg_type !== 3 }">
        <td>
            {{ scheme.scheme.name }}
        </td>
        <td style="text-align: left">
            {{ scheme.scheme.description }}
        </td>
        <td>
            {{ type === "ds" ? scheme.cases.ds : scheme.cases.ks }}
        </td>
    </tr>
</template>

<script>
export default {
    props: ["scheme", "type"]
};
</script>
