<template>
    <v-row justify="center">
        <pack-amount-modal />
        <pack-prices :packBase="packBase" :show="showPrices" />
        <v-col cols="12" lg="8">
            <v-row justify="space-between" no-gutters>
                <v-btn color="success" @click="showPrices = true">
                    <v-icon left>mdi-shuffle</v-icon>
                    Справочник цен
                </v-btn>
                <v-btn @click="isNozoExpanded = !isNozoExpanded">
                    <v-icon left>mdi-shuffle</v-icon>
                    {{
                        isNozoExpanded
                            ? "Вывести одним списком"
                            : "Разбить по нозологиям"
                    }}
                </v-btn>
            </v-row>
            <v-expand-transition>
                <pack-nozo-results v-if="isNozoExpanded" :results="packs" />
                <pack-flat-results
                    v-else
                    :drugs="drugList"
                    :results="flatResults"
                />
            </v-expand-transition>
            <v-row justify="center">
                <v-btn @click="getExcel">Выгрузка Excel</v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { PACKINFO_GET } from "@/store/actions/project";
import PackAmountModal from "./PackResults/PackAmountModal.vue";
import PackNozoResults from "./PackResults/PackNozo.vue";
import PackFlatResults from "./PackResults/PackFlat.vue";
import PackPrices from "./PackResults/PackPrices/PackPrices.vue";

export default {
    components: {
        PackAmountModal,
        PackPrices,
        PackNozoResults,
        PackFlatResults
    },
    data: () => ({
        isNozoExpanded: true,
        showPrices: false,
        packs: null,
        packBase: null
    }),
    mounted() {
        if (!this.packInfo) {
            this.$store.dispatch(PACKINFO_GET).then(() => {
                this.makePackBases();
            });
        } else {
            this.makePackBases();
        }
    },
    methods: {
        makePackBases() {
            this.$store.dispatch("loading", true);
            this.packBase = this.packInfo.packBase;
            this.packs = this.makePacks(this.packInfo.packs);
            this.$store.dispatch("loading", false);
        },
        makePacks(packs) {
            const res = [];
            packs.forEach(nozo => {
                res.push(nozo);
                if (!res[res.length - 1].drugs) return;
                res[res.length - 1].drugs.forEach((drug, di) => {
                    drug.packs.forEach((pack, pi) => {
                        res[res.length - 1].drugs[di].packs[
                            pi
                        ].prices = this.packBase.find(
                            x => x.id === pack.pack_id
                        ).prices;
                    });
                });
            });
            return res;
        },
        getExcel() {
            this.$store.dispatch("loading", true);
            if (!this.isNozoExpanded) this.getFlatExcel();
            else this.getNozoExcel();
        },
        getNozoExcel() {
            this.axios
                .get(
                    `/api/projects/${this.$route.params.id}/packs-excel-nozo`,
                    { responseType: "blob" }
                )
                .then(resp => {
                    const blob = new Blob([resp.data]);
                    const filename = resp.headers["content-disposition"]
                        .split("filename=")[1]
                        .split(";")[0];
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    this.$store.dispatch("loading", false);
                });
        },
        getFlatExcel() {
            this.axios
                .get(
                    `/api/projects/${this.$route.params.id}/packs-excel-flat`,
                    { responseType: "blob" }
                )
                .then(resp => {
                    const blob = new Blob([resp.data]);
                    const filename = resp.headers["content-disposition"]
                        .split("filename=")[1]
                        .split(";")[0];
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    this.$store.dispatch("loading", false);
                });
        }
    },
    computed: {
        packInfo() {
            return this.$store.state.project.packInfo;
        },
        flatResults() {
            const res = [];
            this.packs.forEach(nozo => {
                if (!nozo.drugs) return;
                nozo.drugs.forEach(drug => {
                    drug.packs.forEach(pack => {
                        const exists = res.find(
                            x => x.pack_id === pack.pack_id
                        );
                        if (exists) {
                            exists.amount += pack.amount;
                        } else {
                            res.push({ ...pack });
                        }
                    });
                });
            });
            return res.sort((a, b) => a.name.localeCompare(b.name));
        },
        drugList() {
            const drugArrays = this.packs.map(p => p.drugs);

            if (drugArrays[0] === undefined) return [];
            let drugArray = drugArrays.reduce((a, b) => a.concat(b), []);
            return drugArray.filter(
                (drug, i) =>
                    drugArray.findIndex(d => d.drug_id === drug.drug_id) === i
            );
        }
    }
};
</script>
