<template>
    <v-row v-if="stationaryInfo" justify="center">
        <scheme-changer
            v-if="chosenScheme"
            :scheme="chosenScheme"
            :value="showSchemes"
            @close="showSchemes = false"
            @update="recalculateTotals"
        />
        <ksg-changer
            :ksgBase="stationaryInfo.ksgBase"
            :projectInfo="stationaryInfo.projectInfo"
            :regionBase="stationaryInfo.regionBase"
            :value="showKsgs"
            @close="showKsgs = false"
            @update="e => (stationaryInfo.projectInfo = e)"
        />
        <v-col cols="12" lg="9">
            <v-row align="center" justify="space-between" no-gutters>
                <v-col class="mb-2" cols="12">
                    <v-row class="justify-center" no-gutters>
                        <v-card class="px-2">
                            <v-row
                                align="center"
                                class="flex-nowrap"
                                no-gutters
                            >
                                <v-icon large left>mdi-cash-100</v-icon>
                                <b class="title">
                                    Стационарная медицинская помощь
                                </b>
                            </v-row>
                        </v-card>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-dialog v-model="backDialog" max-width="600" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="warning">
                                Вернуться к амбулаторному распределению
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title
                                class="headline"
                                style="word-break: normal;"
                            >
                                Вы действительно хотите вернуться к
                                амбулаторному распределению?
                            </v-card-title>
                            <v-card-text class="red--text">
                                Все изменения распределения схем круглосуточного
                                и дневного стационаров будут утеряны
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text @click="backDialog = false">
                                    Отменить
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="warning" text @click="goBack">
                                    Подтвердить
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
                <v-col class="text-right" cols="6">
                    <v-btn @click="showKsgs = true">
                        <v-icon left
                            >mdi-clipboard-text-multiple-outline
                        </v-icon>
                        Настройки параметров КСГ
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-card>
                    <v-card-text>
                        <v-col cols="12" class="text--primary subtitle-1">
                            <v-row justify="space-between" no-gutters>
                                <span>
                                    Дневной стационар:
                                </span>
                                <span class="ml-4">
                                    {{ totalDsCases.toLocaleString("ru-RU") }}
                                    случаев,
                                    {{ priceNormalized(totalDsCost) }}
                                </span>
                            </v-row>
                        </v-col>
                        <v-divider> </v-divider>
                        <v-col cols="12" class="text--primary subtitle-1">
                            <v-row justify="space-between" no-gutters>
                                <span>
                                    Круглосуточный стационар:
                                </span>
                                <span class="ml-4">
                                    {{ totalKsCases.toLocaleString("ru-RU") }}
                                    случаев,
                                    {{ priceNormalized(totalKsCost) }}
                                </span>
                            </v-row>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-row>
            <v-row no-gutters align="center" justify="space-between">
                <v-card flat max-width="600px">
                    <v-tabs
                        :value="statType - 1"
                        centered
                        @change="$event => (statType = $event + 1)"
                    >
                        <v-tab>Дневной стационар</v-tab>
                        <v-tab>Круглосуточный стационар</v-tab>
                    </v-tabs>
                </v-card>
                <v-tooltip color="black" open-on-hover top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on" text>
                            Как распределены схемы?
                        </v-chip>
                    </template>
                    <span>
                        Для схем применимых в круглосуточном и дневном
                        стационарах по умолчанию распределение случаев лечения
                        50% на 50%
                    </span>
                </v-tooltip>
            </v-row>
            <v-container class="px-0 pt-2" fluid>
                <v-tabs-items v-if="ksgs" :value="statType - 1">
                    <v-tab-item eager>
                        <ksg-list
                            :ksgs="ksgs[0].ksgs.filter(x => x.schemes)"
                            :projectInfo="stationaryInfo.projectInfo"
                            type="ds"
                            @changeScheme="changeScheme($event)"
                            @updateTotalCases="totalDsCases = $event"
                            @updateTotalCost="totalDsCost = $event"
                        />
                    </v-tab-item>
                    <v-tab-item eager>
                        <ksg-list
                            :ksgs="ksgs[1].ksgs.filter(x => x.schemes)"
                            :projectInfo="stationaryInfo.projectInfo"
                            type="ks"
                            @changeScheme="changeScheme($event)"
                            @updateTotalCases="totalKsCases = $event"
                            @updateTotalCost="totalKsCost = $event"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
            <v-row justify="end" no-gutters>
                <v-btn color="success" @click="proceed">
                    Утвердить распределение схем для круглосуточного и дневного
                    стационара
                </v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import KsgList from "@/components/ProjectResults/KsgResults/KsgStationary/KsgList.vue";
import SchemeChanger from "@/components/ProjectResults/KsgResults/KsgStationary/SchemeChanger.vue";
import KsgChanger from "@/components/ProjectResults/KsgResults/KsgStationary/KsgChanger.vue";
import { KSGPROJECT_SET } from "@/store/actions/project";

export default {
    components: {
        KsgChanger,
        KsgList,
        SchemeChanger
    },
    data: () => ({
        stationaryInfo: null,
        statType: 1,
        showSchemes: false,
        chosenScheme: null,
        ksgs: null,
        showKsgs: false,

        totalDsCases: 0,
        totalKsCases: 0,
        totalDsCost: 0,
        totalKsCost: 0,

        backDialog: false
    }),
    mounted() {
        this.$store.dispatch("loading", true);
        const regionBase = this.axios.get(
            `/api/projects/${this.$route.params.id}/ksg/regions`
        );
        const ksgBase = this.axios.get(
            `/api/projects/${this.$route.params.id}/ksg-base`
        );
        const schemeBase = this.axios.get(
            `/api/projects/${this.$route.params.id}/ksg-scheme-base`
        );
        const ksg = this.axios.get(
            `/api/projects/${this.$route.params.id}/ksg`
        );
        Promise.all([regionBase, ksgBase, schemeBase, ksg]).then(results => {
            this.stationaryInfo = {
                projectInfo: this.$store.state.project.ksgProject,
                regionBase: results[0].data,
                ksgBase: results[1].data,
                schemeBase: results[2].data,
                ksg: results[3].data
            };
            this.$store.dispatch("loading", false);
            this.makeStationaryBase();
        });
    },
    methods: {
        makeStationaryBase() {
            this.stationaryInfo.ksg.forEach((stat, skey) => {
                stat.ksgs.forEach((ksg, kkey) => {
                    if (!ksg.schemes) return;
                    ksg.schemes.forEach((scheme, i) => {
                        // eslint-disable-next-line max-len
                        this.stationaryInfo.ksg[skey].ksgs[kkey].schemes[
                            i
                        ] = this.stationaryInfo.schemeBase.find(
                            x => x.id === scheme
                        );
                    });
                    const [...schemes] = ksg.schemes;
                    const { totalCases } = ksg;
                    this.stationaryInfo.ksg[skey].ksgs[
                        kkey
                    ] = this.stationaryInfo.ksgBase.find(x => x.id === ksg.id);
                    this.stationaryInfo.ksg[skey].ksgs[kkey].schemes = schemes;
                    this.stationaryInfo.ksg[skey].ksgs[
                        kkey
                    ].totalCases = totalCases;
                    this.ksgs = this.stationaryInfo.ksg;
                });
            });
        },
        changeScheme(schemeId) {
            this.chosenScheme = this.stationaryInfo.schemeBase.find(
                x => x.id === schemeId
            );
            this.showSchemes = true;
        },
        recalculateTotals(scheme) {
            const dsKsg = this.stationaryInfo.ksgBase
                .filter(x => x.schemes && x.ksg_type_id === 1)
                .find(x => x.schemes.includes(scheme));
            const ksKsg = this.stationaryInfo.ksgBase
                .filter(x => x.schemes && x.ksg_type_id === 2)
                .find(x => x.schemes.includes(scheme));
            dsKsg.totalCases = dsKsg.schemes.reduce(
                (a, b) => a + b.cases.ds,
                0
            );
            ksKsg.totalCases = ksKsg.schemes.reduce(
                (a, b) => a + b.cases.ks,
                0
            );
        },
        proceed() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/projects/${this.$route.params.id}/ksg/proceed`)
                .then(resp => {
                    this.$store.dispatch(KSGPROJECT_SET, resp.data);
                    this.$store.dispatch("loading", false);
                });
        },
        goBack() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/projects/${this.$route.params.id}/ksg/back`)
                .then(resp => {
                    this.$store.dispatch(KSGPROJECT_SET, resp.data);
                    this.backDialog = false;
                    this.$store.dispatch("loading", false);
                });
        }
    }
};
</script>
