<template>
    <v-row justify="center" no-gutters>
        <v-col lg="9" sm="12">
            <v-row justify="space-between" no-gutters>
                <v-btn color="warning" @click="goBack">Назад</v-btn>
                <v-btn @click="getExcel">Выгрузка Excel</v-btn>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-col class="text-center">
                    <v-card flat>
                        <v-tabs v-model="viewType" grow>
                            <v-tab>
                                Амбулаторное лечение
                            </v-tab>
                            <v-tab>
                                Стационарное лечение
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="viewType">
                            <v-tab-item :key="0">
                                <ksg-ambulatory />
                            </v-tab-item>
                            <v-tab-item :key="1">
                                <ksg-stationary />
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import KsgAmbulatory from "@/components/ProjectResults/KsgResults/KsgFinal/Ambulatory/KsgAmbulatory.vue";
import KsgStationary from "@/components/ProjectResults/KsgResults/KsgFinal/Stationary/KsgStationary.vue";
import { KSGPROJECT_SET } from "@/store/actions/project";

export default {
    name: "KsgFinal",
    components: {
        KsgStationary,
        KsgAmbulatory
    },
    data: () => ({
        viewType: null
    }),
    methods: {
        getExcel() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/projects/${this.$route.params.id}/ksg-excel`, {
                    responseType: "blob"
                })
                .then(resp => {
                    const blob = new Blob([resp.data]);
                    const filename = resp.headers["content-disposition"]
                        .split("filename=")[1]
                        .split(";")[0];
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    this.$store.dispatch("loading", false);
                });
        },
        goBack() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/projects/${this.$route.params.id}/ksg/back`)
                .then(resp => {
                    this.$store.dispatch(KSGPROJECT_SET, resp.data);
                    this.$store.dispatch("loading", false);
                });
        }
    }
};
</script>
