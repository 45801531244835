<template>
    <v-card class="px-1" flat>
        <v-row align="center" justify="center" no-gutters>
            <v-switch
                :input-value="vat"
                class="ma-0 font-weight-bold"
                dense
                hide-details
                inset
                label="Учитывать НДС"
                @change="$emit('change-vat', $event)"
            />
        </v-row>
    </v-card>
</template>
<script>
export default {
    name: "vat-changer",
    computed: {
        vat() {
            return this.$store.state.project.project.vat;
        }
    }
};
</script>
