<template>
    <v-dialog
        :value="value"
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
    >
        <v-card height="100%">
            <v-toolbar>
                <v-toolbar-title>Добавить схемы</v-toolbar-title>
                <v-spacer />
                <v-btn fab icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row
                    align="center"
                    class="mx-4 mt-2"
                    justify="space-between"
                    no-gutters
                >
                    <v-card class="pa-2" outlined>
                        <span>
                            <v-icon color="green">mdi-pill</v-icon>
                            - таблетированные схемы
                        </span>
                        <v-divider vertical></v-divider>
                        <span>
                            <v-icon color="green">mdi-needle</v-icon>
                            - инъекционные схемы
                        </span>
                    </v-card>
                    <v-text-field
                        v-model="search"
                        dense
                        hide-details
                        outlined
                        placeholder="Поиск по схемам"
                        solo
                        style="max-width: 500px;"
                    ></v-text-field>
                </v-row>
                <v-list class="overflow-auto mt-0 pt-0" height="700px">
                    <v-list-item
                        v-for="scheme in filteredUnlistedSchemes"
                        :key="scheme.id"
                        @click="scheme.listed = true"
                    >
                        <v-list-item-icon
                            v-if="scheme.scheme_type === 'tablet'"
                        >
                            <v-icon>mdi-pill</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon
                            v-else-if="scheme.scheme_type === 'inject'"
                        >
                            <v-icon>mdi-needle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="scheme.description"
                            ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-calendar-plus</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: ["schemeBase", "value"],
    data: () => ({
        search: ""
    }),
    computed: {
        unlistedSchemes() {
            return this.schemeBase?.filter(x => !x.listed) || [];
        },
        filteredUnlistedSchemes() {
            return this.unlistedSchemes.filter(x =>
                x.description.toLowerCase().includes(this.search.toLowerCase())
            );
        },
        listedSchemes() {
            return this.schemeBase?.filter(x => x.listed) || [];
        }
    }
};
</script>
