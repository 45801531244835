<template>
    <v-card class="px-1" flat>
        <v-row align="center" justify="center" no-gutters>
            <v-switch
                :input-value="showZvnlp"
                class="ma-0 font-weight-bold"
                dense
                hide-details
                inset
                @change="showZvnlp = !showZvnlp"
                label="Выделить НЕ ЖВНЛП цены"
            />
        </v-row>
    </v-card>
</template>
<script>
export default {
    name: "zvnlp-changer",
    props: {
        showZvnlp: {}
    }
};
</script>
