<style>
.v-data-footer__select {
    flex-basis: auto;
}

.v-data-footer__select .v-select {
    flex-basis: 50px;
}

.v-data-footer__select .v-select .v-select__selections {
    flex-basis: auto;
    min-width: 25px;
}

.v-list-item__content {
    flex: 1 1 auto;
    min-width: 50px;
}
</style>
<template>
    <v-dialog
        :value="show"
        @click:outside="$parent.showPrices = false"
        @keydown.esc="$parent.showPrices = false"
    >
        <v-overlay :value="isLoading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <pack-price-changer
            v-if="packToChange"
            :pack="packToChange"
            @close="packToChange = null"
            @update="updatePack"
        />
        <v-card height="100%">
            <v-toolbar>
                <v-toolbar-title>Закупочные цены упаковок</v-toolbar-title>
                <v-spacer />
                <v-btn fab icon @click="$parent.showPrices = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row
                    align="center"
                    class="mt-2"
                    justify="space-between"
                    no-gutters
                >
                    <v-text-field
                        v-model="filter"
                        dense
                        hide-details
                        label="Поиск"
                        outlined
                    />
                    <v-divider class="mx-2 my-2" inset vertical />
                    <v-card outlined max-width="280px">
                        <v-row align="center" justify="center" no-gutters>
                            <vat-changer
                                class="col-12"
                                @change-vat="changeVat"
                            />
                            <zvnlp-changer :show-zvnlp="showZvnlp" />
                        </v-row>
                    </v-card>
                    <v-divider
                        class="mx-2 my-2 hidden-md-and-down"
                        inset
                        vertical
                    />
                    <rat-changer
                        class="col col-lg col-sm-12 col-md-12 my-md-2 my-sm-2"
                        @change-rat="changeRat"
                    />
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="filteredPackBase"
                    :items-per-page="5"
                    no-data-text="Ничего не найдено."
                >
                    <template v-slot:item.name="{ item }">
                        {{ item.name }} <br />
                        - {{ item.description }} <br />
                        - {{ item.form }}
                    </template>
                    <template v-slot:header.prices.defaultPrice="{ header }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{
                                    header.text
                                }}</span>
                            </template>
                            <span>
                                Средняя цена за упаковку на основании данных
                                государственных закупок по РФ
                            </span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.prices.defaultPrice="{ item }">
                        {{ priceNormalized(item.prices.defaultPrice) }}
                    </template>
                    <template v-slot:item.prices.price="{ item }">
                        <span
                            :class="{
                                red: !item.zvnlp && showZvnlp,
                                'light-green--text':
                                    Number(item.prices.price) !==
                                    Number(item.prices.defaultPrice)
                            }"
                        >
                            {{ priceNormalized(getPackPrice(item)) }}
                        </span>
                    </template>
                    <template v-slot:item.test="{ item }">
                        <v-btn color="success" @click="updatePackPrice(item)">
                            Изменить
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { PROJECT_SET } from "@/store/actions/project";
import VatChanger from "@/components/ProjectResults/AmountResults/PackResults/PackPrices/VatChanger.vue";
import RatChanger from "@/components/ProjectResults/AmountResults/PackResults/PackPrices/RatChanger.vue";
import PackPriceChanger from "@/components/ProjectResults/AmountResults/PackResults/PackPrices/PackPriceChanger.vue";
import ZvnlpChanger from "@/components/ProjectResults/AmountResults/PackResults/PackPrices/ZvnlpChanger";

export default {
    data: () => ({
        isLoading: false,
        filter: "",
        headers: [
            {
                text:
                    "Торговое наименование + Лекарственная форма + Упаковка (полная)",
                value: "name"
            },
            {
                text: "Базовая цена в расчёте",
                value: "prices.defaultPrice",
                width: "200px",
                align: "end"
            },
            {
                text: "Текущая цена в расчёте",
                value: "prices.price",
                width: "200px",
                align: "end"
            },
            {
                text: "",
                value: "test",
                width: "100px"
            }
        ],
        packToChange: null,
        showZvnlp: false
    }),
    components: {
        ZvnlpChanger,
        RatChanger,
        VatChanger,
        PackPriceChanger
    },
    props: ["packBase", "show"],
    computed: {
        filteredPackBase() {
            return (
                this.packBase?.filter(x => {
                    if (
                        x.name
                            .toLowerCase()
                            .includes(this.filter.toLowerCase()) ||
                        x.description
                            .toLowerCase()
                            .includes(this.filter.toLowerCase()) ||
                        x.form
                            .toLowerCase()
                            .includes(this.filter.toLowerCase()) ||
                        x.prices.price
                            .toLowerCase()
                            .includes(this.filter.toLowerCase()) ||
                        x.prices.defaultPrice
                            .toLowerCase()
                            .includes(this.filter.toLowerCase()) ||
                        String(this.getPackPrice(x))
                            .toLowerCase()
                            .includes(this.filter.toLowerCase())
                    ) {
                        return true;
                    }
                    return false;
                }) ?? []
            );
        }
    },
    methods: {
        changeVat(val) {
            this.isLoading = true;
            const vat = Boolean(val);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/vat`, { vat })
                .then(resp => {
                    this.$store.dispatch(PROJECT_SET, resp.data);
                    this.isLoading = false;
                });
        },
        changeRat(val) {
            this.isLoading = true;
            const rat = val;
            this.axios
                .post(`/api/projects/${this.$route.params.id}/rat`, { rat })
                .then(resp => {
                    this.$store.dispatch(PROJECT_SET, resp.data);
                    this.isLoading = false;
                });
        },
        updatePackPrice(pack) {
            this.packToChange = pack;
        },
        updatePack(newPrice) {
            this.$set(this.packToChange.prices, "price", newPrice.toString());
            this.packToChange = null;
        }
    }
};
</script>
