<template>
    <v-expansion-panels hover>
        <ksg-scheme
            v-for="scheme in schemes"
            :key="scheme.id"
            :scheme="scheme"
        />
    </v-expansion-panels>
</template>

<script>
import KsgScheme from "@/components/ProjectResults/KsgResults/KsgFinal/Ambulatory/KsgScheme.vue";

export default {
    components: { KsgScheme },
    props: ["schemes"],
    name: "KsgAmbulatoryList"
};
</script>

<style scoped></style>
