<template>
    <v-row justify="center" no-gutters>
        <v-col lg="8" sm="12">
            <v-row justify="center" no-gutters>
                <p class="title text-center col-12">
                    Для загрузки базовых параметров системы оплаты выберите
                    субъект РФ
                </p>
                <v-autocomplete
                    v-model="selectedRegionId"
                    :items="regions"
                    class="col-lg-4 col-sm-8 col-xs-12"
                    dense
                    hide-details
                    item-text="name"
                    item-value="id"
                    label="Выберите область"
                    shaped
                    solo
                />
            </v-row>
            <v-row
                justify="center"
                v-if="obsolete"
                class="warning--text"
                no-gutters
            >
                Данные в данном регионе могут быть неактуальны. Для того чтобы
                расчёт был надёжней - введите данные вручную.
            </v-row>
            <v-row justify="center">
                <v-btn
                    :disabled="!selectedRegionId"
                    color="primary"
                    @click="selectCountry"
                >
                    Выбрать
                </v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { KSGPROJECT_SET } from "@/store/actions/project";

export default {
    data: () => ({
        selectedRegionId: null,
        regions: []
    }),
    mounted() {
        this.$store.dispatch("loading", true);
        this.axios
            .get(`/api/projects/${this.$route.params.id}/ksg`)
            .then(resp => {
                this.regions = resp.data;
                this.$store.dispatch("loading", false);
            });
    },
    methods: {
        selectCountry() {
            this.$store.dispatch("loading", true);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/ksg/regions`, {
                    region_id: this.selectedRegionId
                })
                .then(() => {
                    this.axios
                        .get(
                            `/api/projects/${this.$route.params.id}/ksg/proceed`
                        )
                        .then(resp => {
                            this.$store.dispatch(KSGPROJECT_SET, resp.data);
                            this.$store.dispatch("loading", false);
                        });
                });
        }
    },
    computed: {
        obsolete() {
            return (
                this.regions.find(x => x.id === this.selectedRegionId)
                    ?.obsolete || false
            );
        }
    }
};
</script>
