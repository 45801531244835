var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"px-4",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-down")])]}}])},[_c('span',[_vm._v("Посмотреть перечень лекарственных препаратов")])])]},proxy:true}])},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.scheme.using,"icon":"","small":""},nativeOn:{"click":function($event){return _vm.unlistScheme($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Убрать схему из списка")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},nativeOn:{"click":function($event){return _vm.switchScheme($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.scheme.using ? 'primary' : ''}},[_vm._v("mdi-check-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Применять схему амбулаторно")])]),(_vm.scheme.scheme_type === 'tablet')?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pill")]):(_vm.scheme.scheme_type === 'inject')?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-needle")]):_vm._e(),_c('v-tooltip',{attrs:{"open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({},'v-col',attrs,false),on),[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.scheme.description)+" ")]),_c('p',{staticClass:"my-0 mx-2"},[_vm._v(" "+_vm._s(_vm.priceNormalized(_vm.scheme.totalPrice))+" ")])],1)],1)]}}])},[_c('span',[_vm._v(" Используется в: "),_vm._l((_vm.scheme.nozologies),function(nozo,i){return _c('span',{key:nozo},[_vm._v(" "+_vm._s(nozo + (i === _vm.scheme.nozologies.length - 1 ? "" : ", "))+" ")])})],2)])],1)],1),_c('v-expansion-panel-content',[_c('v-expansion-panels',{staticClass:"mt-4"},_vm._l((_vm.scheme.drugs),function(drug,i){return _c('ksg-drug',{key:drug.id,attrs:{"drug":drug},on:{"priceUpdate":function ($event) {
                        _vm.$set(_vm.scheme.drugs[i], 'totalPrice', $event);
                        _vm.recalculatePrice();
                    }}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }