<template>
    <v-container fluid>
        <v-row justify="center" no-gutters>
            <v-btn-toggle
                :value="displayForm"
                dense
                mandatory
                rounded
                @change="changeDisplayForm"
            >
                <v-btn :value="1" class="pl-4">
                    В единицах действующего вещества
                </v-btn>
                <v-btn :value="2" class="pl-4">
                    В упаковках лекарственных препаратов
                </v-btn>
            </v-btn-toggle>
        </v-row>
        <v-mg-results v-if="displayForm === 1"></v-mg-results>
        <v-pack-results v-else-if="displayForm === 2"></v-pack-results>
    </v-container>
</template>

<script>
import { PROJECT_SET } from "@/store/actions/project";
import VMgResults from "./AmountResults/MGResults.vue";
import VPackResults from "./AmountResults/PackResults.vue";

export default {
    components: {
        VMgResults,
        VPackResults
    },
    name: "AmountResults",
    computed: {
        displayForm() {
            return this.$store.state.project.project?.project_display_form_id;
        }
    },
    methods: {
        changeDisplayForm(displayForm) {
            this.$store.dispatch("loading", true);
            this.axios
                .post(`/api/projects/${this.$route.params.id}/form`, {
                    display_form: displayForm
                })
                .then(resp => {
                    this.$store.dispatch(PROJECT_SET, resp.data);
                    this.$store.dispatch("loading", false);
                });
        }
    }
};
</script>

<style scoped></style>
