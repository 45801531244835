<template>
    <v-row justify="space-between" no-gutters>
        <v-col cols="3">
            <v-question-list
                :qlist="questionList"
                v-if="questionList.length"
                v-model="choosenQuestion"
            />
        </v-col>
        <v-spacer style="width: 8px; max-width: 8px;" />
        <v-col>
            <v-question-group
                :closest="{ prev: !isFirstQuestion, next: !isLastQuestion }"
                :key="choosenQuestion"
                @back="previous"
                @next="proceed"
                v-if="questionList.length"
                v-model="questionList[choosenQuestion]"
            />
            <div class="text-center headline" v-else>
                Нет параметров для изменений. <br />
                Перейдите к следующему вопросу.
            </div>
        </v-col>
    </v-row>
</template>

<script>
import VQuestionList from "./QuestionList.vue";
import VQuestionGroup from "./QuestionGroup.vue";

export default {
    components: {
        VQuestionList,
        VQuestionGroup
    },
    props: ["baseQuestionList"],
    data: () => ({
        choosenQuestion: 0,
        questionList: [],
        json: {}
    }),
    mounted() {
        this.makeQuestionList(this.baseQuestionList);
    },
    methods: {
        makeQuestionList(source) {
            this.questionList = [];
            source.forEach(q => {
                if (!q.parent_id) {
                    this.questionList.push({ ...q });
                } else {
                    const parentQ = this.questionList.find(
                        p => p.id === q.parent_id
                    );
                    if (!parentQ.children) {
                        parentQ.children = [];
                    }
                    parentQ.children.push({ ...q });
                }
            });
        },
        proceed(shouldSave) {
            const q = this.questionList[this.choosenQuestion];
            if (shouldSave || !q.passed) {
                this.postQuestion(q);
            } else {
                this.goNext();
            }
        },
        goNext() {
            this.makeQuestionList(this.baseQuestionList);
            if (!this.isLastQuestion) {
                this.choosenQuestion += 1;
            } else {
                this.$emit("next");
            }
        },
        previous() {
            this.choosenQuestion -= 1;
        },
        postQuestion(q) {
            this.$store.dispatch("loading", true);
            const questions = [];
            if (q.children) {
                q.children.forEach(child => {
                    questions.push(child);
                });
            } else {
                questions.push(q);
            }
            const postData = {};
            questions.forEach(qs => {
                qs.answers.forEach(a => {
                    postData[a.id] = a.ratio;
                });
            });
            this.axios
                .post(`/api/projects/${this.$route.params.id}/questions`, {
                    questions: postData
                })
                .then(() => {
                    this.$store.dispatch("loading", false);

                    questions.forEach(qs => {
                        const toChange = this.baseQuestionList.find(
                            el => el.id === qs.id
                        );
                        toChange.passed = true;
                        if (toChange.parent_id) {
                            const parent = this.baseQuestionList.find(
                                el => el.id === toChange.parent_id
                            );
                            parent.passed = true;
                        }
                    });
                    this.goNext();
                });
        }
    },
    computed: {
        isFirstQuestion() {
            return this.choosenQuestion === 0;
        },
        isLastQuestion() {
            return this.choosenQuestion === this.questionList.length - 1;
        }
    }
};
</script>
