<template>
    <v-data-table
        :headers="headers"
        :items="ksgTable"
        dense
        disable-filtering
        disable-pagination
        hide-default-footer
        item-key="id"
        show-expand
        @click:row="onRowClick"
    >
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-2">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Код</th>
                                <th>Схема</th>
                                <th>Случаи</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ksg-scheme
                                v-for="scheme in item.schemes"
                                :key="scheme.id"
                                :scheme="scheme"
                                :type="type"
                                @changeScheme="$emit('changeScheme', scheme.id)"
                            />
                        </tbody>
                    </template>
                </v-simple-table>
            </td>
        </template>
        <template v-slot:body.append>
            <tr>
                <td v-for="n in new Array(3)" :key="n"></td>
                <td class="subtitle-2" style="text-align: right">
                    Всего случаев: {{ amountNormalized(totalCases(ksgs)) }}
                </td>
                <td class="subtitle-2" colspan="2">
                    Общая стоимость: {{ priceNormalized(totalCost(ksgs)) }}
                </td>
            </tr>
        </template>
    </v-data-table>
    <!--v-expansion-panels hover>
        <ksg
            v-for="ksg in ksgs"
            :key="ksg.id"
            :ksg="ksg"
            :projectInfo="projectInfo"
            :type="type"
            @changeScheme="$emit('changeScheme', $event)"
        />
        <@priceUpdate="
        $event => {
          $set(ksg[i], 'totalPrice', $event);
        }
      ">
    </v-expansion-panels !-->
</template>

<script>
//import Ksg from "@/components/ProjectResults/KsgResults/KsgStationary/Ksg.vue";

import KsgScheme from "@/components/ProjectResults/KsgResults/KsgStationary/KsgScheme";

export default {
    props: ["type", "ksgs", "projectInfo"],
    components: {
        //Ksg,
        KsgScheme
    },
    data: () => ({
        headers: [
            {
                text: "КСГ",
                value: "name",
                width: "1px"
            },
            {
                text: "Полное наименование",
                value: "full_name"
            },
            {
                text: "Тариф",
                value: "price",
                align: "right",
                width: "1px"
            },
            {
                text: "Кол-во случаев",
                class: ["flex-nowrap nowrap text-no-wrap"],
                value: "cases",
                align: "right",
                width: "1px"
            },
            {
                text: "Стоимость",
                value: "cost",
                align: "right",
                width: "1px"
            },
            {
                align: "right",
                text: "",
                value: "data-table-expand",
                width: "1px"
            }
        ]
    }),
    methods: {
        onRowClick(ksg, row) {
            row.expand(!row.isExpanded);
        },
        ksgPrice(ksg) {
            const kz = Number(ksg.kz);
            const zp = Number(ksg.zp);
            const ku = Number(ksg.pivot.ku);
            const skus = Number(
                this.type === "ds"
                    ? this.projectInfo.ds_skus
                    : this.projectInfo.ks_skus
            );
            const bs = Number(
                this.type === "ds"
                    ? this.projectInfo.ds_bs
                    : this.projectInfo.ks_bs
            );
            const kd = Number(this.projectInfo.kd);
            return bs * kz * (1 - zp + zp * ku * skus * kd);
        },
        cases(ksg) {
            ksg.totalCases =
                ksg.schemes.reduce(
                    (a, b) =>
                        a + (this.type === "ds" ? b.cases.ds : b.cases.ks),
                    0
                ) || ksg.totalCases;
            // this.cases = this.ksg.cases;
            return ksg.totalCases;
        },
        cost(ksg) {
            ksg.totalCost = this.ksgPrice(ksg) * this.cases(ksg);
            return ksg.totalCost;
        },
        totalCases() {
            const totalCases =
                this.ksgs.reduce((a, b) => a + b.totalCases, 0) ||
                this.ksgs.totalCases;
            this.$emit("updateTotalCases", totalCases);
            return totalCases;
        },
        totalCost() {
            const totalCost =
                this.ksgs.reduce((a, b) => a + b.totalCost, 0) ||
                this.ksgs.totalCost;
            this.$emit("updateTotalCost", totalCost);
            return totalCost;
        }
    },
    computed: {
        ksgTable() {
            return this.ksgs.map(ksg => {
                const ksgPrice = this.ksgPrice(ksg);
                const cases = this.cases(ksg);
                const totalPrice = this.cost(ksg);
                return {
                    id: ksg.id,
                    name: ksg.name,
                    full_name: ksg.full_name,
                    price: this.priceNormalized(ksgPrice),
                    cases: this.amountNormalized(cases),
                    cost: this.priceNormalized(totalPrice),
                    schemes: ksg.schemes
                };
            });
        }
    }
};
</script>
