<template>
    <v-card flat>
        <v-row justify="center" no-gutters>
            <v-col cols="12">
                <v-answers-parent
                    @update="onAnswersUpdate"
                    v-model="value"
                    v-if="value.children"
                />
                <v-answers-group
                    @update="onAnswerUpdate"
                    v-model="value.answers"
                    :question="value.question"
                    :path="value.aPath"
                    v-else
                />
            </v-col>
        </v-row>
        <v-row justify="space-between" no-gutters>
            <v-col>
                <v-btn class="ma-2" v-if="closest.prev" @click="$emit('back')"
                    >К предыдущему вопросу</v-btn
                >
            </v-col>
            <v-col class="text-right">
                <v-btn
                    class="ma-2"
                    color="primary"
                    :disabled="!canProceed"
                    @click="$emit('next', touched)"
                    >{{
                        closest.next ? "Далее" : "Перейти к следующей нозологии"
                    }}</v-btn
                >
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import VAnswersGroup from "./AnswersGroup.vue";
import VAnswersParent from "./AnswersParent.vue";

export default {
    props: ["value", "closest"],
    data: () => ({
        canProceed: true,
        defaultRatios: []
    }),
    mounted() {
        this.defaultRatios = this.getRatios();
    },
    components: {
        VAnswersGroup,
        VAnswersParent
    },
    methods: {
        getRatios() {
            if (this.value.children) {
                return this.value.children.map(child =>
                    child.answers.map(a => a.ratio)
                );
            }
            return this.value.answers.map(a => a.ratio);
        },
        onAnswersUpdate(canProceed) {
            this.canProceed = canProceed;
        },
        onAnswerUpdate(answerArray) {
            let total = answerArray.reduce((a, b) => a + b.ratio * 10000, 0);
            this.canProceed = total === 10000;
        }
    },
    computed: {
        touched() {
            return !this.getRatios().every(
                (el, i) => el === this.defaultRatios[i]
            );
        }
    }
};
</script>
