<template>
    <v-row>
        <v-col
            cols="12"
            @click.ctrl="$store.commit('testMode', !$store.state.testMode)"
        >
            <v-col v-if="$store.state.testMode" style="text-align: center">
                <v-btn
                    color="success"
                    outlined
                    @click="historyMode = !historyMode"
                >
                    {{
                        !historyMode
                            ? "Тонкая настройка"
                            : "Вернуться к грубой настройке"
                    }}
                </v-btn>
            </v-col>
            <v-pseudo-answers-group
                v-if="!historyMode"
                v-model="value.children[0].answers"
                :path="value.aPath"
                :question="value.question"
                class="mb-2"
                @update="updateAll"
            />

            <v-answers-group
                v-for="(subq, i) in value.children"
                v-else
                :key="i"
                v-model="subq.answers"
                :path="subq.aPath"
                :question="subq.question"
                class="mb-2"
                @update="updateAnswer(i, $event)"
            />
        </v-col>
    </v-row>
</template>

<script>
import VAnswersGroup from "./AnswersGroup.vue";
import VPseudoAnswersGroup from "./PseudoAnswersGroup.vue";

export default {
    components: {
        VPseudoAnswersGroup,
        VAnswersGroup
    },
    props: ["value"],
    data: () => ({
        historyMode: false,
        totalValues: []
    }),
    mounted() {
        this.updateAnswers();
    },
    methods: {
        updateAll(answers) {
            this.value.children.forEach((child, ci) => {
                child.answers.forEach((ans, i) => {
                    this.value.children[ci].answers[i].ratio = answers[i].ratio;
                });
            });
            this.updateAnswers();
        },
        updateAnswers() {
            this.totalValues = [];
            this.value.children.forEach(child => {
                const toAdd = [];
                child.answers.forEach(a => {
                    toAdd.push(a.ratio);
                });
                this.totalValues.push(toAdd);
            });
        },
        updateAnswer(id, val) {
            const toUpdate = [];
            val.forEach(a => {
                toUpdate.push(a.ratio);
            });
            this.$set(this.totalValues, id, toUpdate);
        }
    },
    computed: {
        canProceed() {
            return this.totalValues.every(
                ratioArray => ratioArray.reduce((a, b) => a + b) === 1
            );
        }
    },
    watch: {
        canProceed() {
            this.$emit("update", this.canProceed);
        }
    }
};
</script>

<style></style>
