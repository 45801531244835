<template>
    <v-dialog :value="drug" max-width="1200" persistent>
        <v-overlay :value="isLoading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-slide-x-transition>
            <v-card v-if="drug">
                <v-card-text class="pa-0">
                    <v-simple-table class="mb-4 mt-2">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Торговое наименование - Лекарственная
                                        форма - Упаковка (полная)
                                    </th>
                                    <th class="text-right">Цена</th>
                                    <th class="text-right">Количество</th>
                                    <th class="text-right">
                                        Перераспределение
                                    </th>
                                    <th class="text-right">Стоимость</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(pack, i) in drug.packs"
                                    :key="pack.id"
                                >
                                    <td>
                                        {{
                                            pack.name +
                                                " - " +
                                                pack.description +
                                                " - " +
                                                pack.form
                                        }}
                                    </td>
                                    <td>
                                        <span
                                            :class="{
                                                'lime lighten-3':
                                                    pack.prices.price !==
                                                    pack.prices.defaultPrice
                                            }"
                                        >
                                            {{
                                                priceNormalized(
                                                    getPackPrice(pack)
                                                )
                                            }}
                                        </span>
                                    </td>
                                    <td align="right">
                                        {{ amountNormalized(pack.amount) }}
                                    </td>
                                    <pack-changer
                                        v-model="drug.packs[i]"
                                        :rest="restSize"
                                    />
                                    <td align="right">
                                        {{
                                            priceNormalized(
                                                getPackPrice(pack) * pack.amount
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left" colspan="3">
                                        {{
                                            restSize > 0
                                                ? "Не распределено: " +
                                                  unitNormalized(restSize) +
                                                  " " +
                                                  drug.unit_type +
                                                  "."
                                                : "Всё распределено!"
                                        }}
                                    </td>
                                    <td align="right" colspan="4">
                                        Итого:
                                        {{ priceNormalized(totalPrice) }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="darken-1" @click="cancel">
                        Отменить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="restSize > 0"
                        color="primary"
                        @click="update"
                    >
                        Подтвердить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-slide-x-transition>
    </v-dialog>
</template>
<script>
import { PACKS_CLEAR } from "@/store/actions/packs";
import PackChanger from "@/components/ProjectResults/AmountResults/PackResults/PackChanger.vue";

export default {
    name: "pack-amount-modal",
    data: () => ({
        isLoading: false
    }),
    components: { PackChanger },
    computed: {
        drug() {
            return this.$store.state.packs.drug;
        },
        totalPrice() {
            return this.drug.packs.reduce(
                (cnt, o) => cnt + this.getPackPrice(o) * o.amount,
                0
            );
        },
        totalSize() {
            return this.drug.packs.reduce((a, b) => a + b.size * b.amount, 0);
        },
        restSize() {
            return Number(this.drug.size) - this.totalSize;
        },
        amounts() {
            const res = {};
            this.drug.packs.forEach(el => {
                res[el.id] = el.amount;
            });
            return res;
        }
    },
    methods: {
        cancel() {
            this.$store.dispatch(PACKS_CLEAR);
        },
        update() {
            this.isLoading = true;
            this.axios
                .post(`/api/projects/${this.$route.params.id}/packs`, {
                    amounts: this.amounts
                })
                .then(() => {
                    this.isLoading = false;
                    this.$store.dispatch(PACKS_CLEAR);
                });
        }
    }
};
</script>
