<template>
    <v-dialog :value="true" max-width="400px" persistent>
        <v-card>
            <v-card-title>
                {{ pack.name }}
            </v-card-title>
            <v-card-subtitle>
                - {{ pack.description }} <br />
                - {{ pack.form }}
            </v-card-subtitle>
            <v-card-text class="pt-4">
                <v-text-field
                    v-model="val"
                    :max="max"
                    append-outer-icon="mdi-currency-rub"
                    dense
                    hide-details
                    min="0"
                    outlined
                    single-line
                    step="0.01"
                    type="number"
                    @keypress="validate($event)"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn @click="close">
                    Отменить
                </v-btn>
                <v-spacer />
                <v-btn color="primary" @click="updatePrice">
                    Применить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: ["value", "pack"],
    name: "pack-price-changer",
    data: () => ({
        val: 0,
        max: 999999999
    }),
    mounted() {
        this.val = this.getPackPrice(this.pack);
    },
    methods: {
        validate(event) {
            const val = Number(String(this.val) + event.key);
            if (val > this.max) {
                event.preventDefault();
                this.val = this.max;
            }
        },
        updatePrice() {
            const data = {};
            let realPrice = this.val;
            if (!this.vat) realPrice /= 0.9;
            if (this.rat > 0) {
                realPrice /= 1 + this.rat / 100;
            }
            this.$emit("update", realPrice);
            data[this.pack.id] = realPrice;
            this.axios
                .post(`/api/projects/${this.$route.params.id}/packs`, {
                    prices: data
                })
                .then(() => {
                    this.$emit("update", realPrice);
                });
        },
        close() {
            this.$emit("close");
        }
    },
    computed: {
        vat() {
            return this.$store.state.project.project.vat;
        },
        rat() {
            return this.$store.state.project.project.rat;
        }
    }
};
</script>
