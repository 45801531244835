<template>
    <v-row justify="center">
        <v-col>
            <v-row align="center" justify="center">
                <v-text-field
                    v-model="search"
                    dense
                    outlined
                    placeholder="Поиск по любому параметру"
                    solo
                    style="max-width: 500px;"
                ></v-text-field>
            </v-row>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">АТХ</th>
                            <th class="text-left">МНН</th>
                            <th class="text-left">Путь введения</th>
                            <th class="text-right">Количество</th>
                            <th class="text-center">Единица измерения</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="drug in filteredResults" :key="drug.id">
                            <td>{{ drug.code }}</td>
                            <td>{{ drug.name }}</td>
                            <td>
                                {{ drug.inject_type }}
                            </td>
                            <td align="right">
                                {{ unitNormalized(drug.size) }}
                            </td>
                            <td align="center">
                                {{ drug.unit_type }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: ["results"],
    data: () => ({
        search: ""
    }),
    computed: {
        filteredResults() {
            if (!this.search) return this.results;
            const search = this.search.toLowerCase();
            const filteredResults = [];
            this.results.forEach(result => {
                const conditions = [
                    result.code.toLowerCase().includes(search),
                    result.name.toLowerCase().includes(search),
                    result.inject_type.toLowerCase().includes(search),
                    Number(result.size)
                        .toFixed(2)
                        .includes(search.replaceAll(" ", "").replace(",", "."))
                ];
                if (conditions.includes(true)) filteredResults.push(result);
            });
            return filteredResults;
        }
    }
};
</script>
