<template>
    <v-row justify="center">
        <v-col>
            <v-row align="center" justify="center">
                <v-text-field
                    v-model="search"
                    dense
                    outlined
                    placeholder="Поиск по любому параметру"
                    solo
                    style="max-width: 500px;"
                ></v-text-field>
            </v-row>
            <v-simple-table class="my-4">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">МНН</th>
                            <th class="text-left">Торговое наименование</th>
                            <th class="text-left">Упаковка (полная)</th>
                            <th class="text-left">Форма</th>
                            <th class="text-right">Цена</th>
                            <th class="text-right">Количество</th>
                            <th class="text-right">Стоимость</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="pack in filteredResults" :key="pack.id">
                            <td>
                                {{ getMnn(pack) }}
                            </td>
                            <td>{{ pack.name }}</td>
                            <td>{{ pack.description }}</td>
                            <td>{{ pack.form }}</td>
                            <td>
                                <span
                                    :class="{
                                        'lime lighten-3':
                                            pack.prices.price !==
                                            pack.prices.defaultPrice
                                    }"
                                >
                                    {{ priceNormalized(getPackPrice(pack)) }}
                                </span>
                            </td>
                            <td align="right">
                                {{ amountNormalized(pack.amount) }}
                            </td>
                            <td align="right">
                                {{
                                    priceNormalized(
                                        pack.amount * getPackPrice(pack)
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td align="right" colspan="6">
                                Итого:
                                {{ priceNormalized(totalPrice(results)) }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: ["drugs", "results"],
    data: () => ({
        search: ""
    }),
    computed: {
        filteredResults() {
            if (!this.search) return this.results;
            const search = this.search.toLowerCase();
            const filteredResults = [];
            this.results.forEach(result => {
                const conditions = [
                    this.getMnn(result)
                        .toLowerCase()
                        .includes(search),
                    result.name.toLowerCase().includes(search),
                    result.description.toLowerCase().includes(search),
                    result.form.toLowerCase().includes(search),
                    Number(this.getPackPrice(result))
                        .toFixed(2)
                        .includes(search.replaceAll(" ", "").replace(",", ".")),
                    Number(result.amount)
                        .toFixed(2)
                        .includes(search.replaceAll(" ", "").replace(",", ".")),
                    Number(result.amount * this.getPackPrice(result))
                        .toFixed(2)
                        .includes(search.replaceAll(" ", "").replace(",", "."))
                ];
                if (conditions.includes(true)) filteredResults.push(result);
            });
            return filteredResults;
        }
    },
    methods: {
        totalPrice(packs) {
            return packs.reduce(
                (cnt, o) => cnt + this.getPackPrice(o) * o.amount,
                0
            );
        },
        getMnn(pack) {
            return this.drugs.find(x => x.drug_id === pack.drug_id).name;
        }
    }
};
</script>
