<template>
    <v-container v-if="ready" class="pa-0" fluid>
        <v-card class="my-2">
            <v-tabs v-model="statType" grow>
                <v-tab :key="1">
                    Дневной стационар ({{
                        totalDsCases.toLocaleString("ru-RU")
                    }}
                    случаев,
                    {{ priceNormalized(Math.round(totalDsPrice)) }})
                </v-tab>
                <v-tab :key="2">
                    Круглосуточный стационар ({{
                        totalKsCases.toLocaleString("ru-RU")
                    }}
                    случаев,
                    {{ priceNormalized(Math.round(totalKsPrice)) }})
                </v-tab>
            </v-tabs>
        </v-card>
        <v-card>
            <v-card-text>
                <v-tabs-items v-model="statType">
                    <v-tab-item :key="1">
                        <ksg-list
                            :ksgBase="ksgBase.filter(x => x.ksg_type_id === 1)"
                            :projectInfo="projectInfo"
                            :schemes="
                                schemeBase.filter(x => x.scheme.ds_ksg_id)
                            "
                            type="ds"
                        />
                    </v-tab-item>
                    <v-tab-item :key="2">
                        <ksg-list
                            :ksgBase="ksgBase.filter(x => x.ksg_type_id === 2)"
                            :projectInfo="projectInfo"
                            :schemes="
                                schemeBase.filter(x => x.scheme.ks_ksg_id)
                            "
                            type="ks"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import KsgList from "@/components/ProjectResults/KsgResults/KsgFinal/Stationary/KsgList.vue";

export default {
    name: "KsgStationary",
    components: { KsgList },
    data: () => ({
        statType: 1,
        ready: false,

        ksgBase: null,
        schemeBase: null
    }),
    mounted() {
        this.getStationaryResults();
    },
    methods: {
        getStationaryResults() {
            this.$store.dispatch("loading", true);
            const ksgBaseRequest = this.axios.get(
                `/api/projects/${this.$store.state.project.project.id}/ksg-base`
            );
            const schemeBaseRequest = this.axios.get(
                `/api/projects/${this.$store.state.project.project.id}/ksg-scheme-base`
            );
            Promise.all([ksgBaseRequest, schemeBaseRequest]).then(resp => {
                this.ksgBase = resp[0].data;
                this.schemeBase = resp[1].data;
                this.$store.dispatch("loading", false);
                this.ready = true;
            });
        },
        getTotalPrice(ksg) {
            const cases = this.getKsgSchemes(ksg).reduce(
                (a, b) => a + (ksg.ksg_type_id === 1 ? b.cases.ds : b.cases.ks),
                0
            );
            const price = this.getKsgPrice(ksg);
            return cases * price;
        },
        getKsgPrice(ksg) {
            const kz = Number(ksg.kz);
            const zp = Number(ksg.zp);
            const ku = Number(ksg.pivot.ku);
            const skus = Number(
                ksg.ksg_type_id === 1
                    ? this.projectInfo.ds_skus
                    : this.projectInfo.ks_skus
            );
            const bs = Number(
                ksg.ksg_type_id === 1
                    ? this.projectInfo.ds_bs
                    : this.projectInfo.ks_bs
            );
            const kd = Number(this.projectInfo.kd);
            return bs * kz * (1 - zp + zp * ku * skus * kd);
        },
        getKsgSchemes(ksg) {
            if (ksg.ksg_type_id === 1) {
                return this.schemeBase.filter(
                    x => x.scheme.ds_ksg_id === ksg.id
                );
            }
            return this.schemeBase.filter(x => x.scheme.ks_ksg_id === ksg.id);
        }
    },
    computed: {
        projectInfo() {
            return this.$store.state.project.ksgProject;
        },
        totalDsCases() {
            return this.schemeBase.reduce((a, b) => a + b.cases.ds, 0);
        },
        totalKsCases() {
            return this.schemeBase.reduce((a, b) => a + b.cases.ks, 0);
        },
        totalDsPrice() {
            return this.ksgBase
                .filter(x => x.ksg_type_id === 1)
                .reduce((a, b) => a + this.getTotalPrice(b) || 0, 0);
        },
        totalKsPrice() {
            return this.ksgBase
                .filter(x => x.ksg_type_id === 2)
                .reduce((a, b) => a + this.getTotalPrice(b) || 0, 0);
        }
    }
};
</script>

<style scoped></style>
