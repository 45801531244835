<template>
    <v-card class="mx-auto">
        <v-list dense>
            <v-list-item-group
                :value="value"
                @change="selectQuestion"
                mandatory
                color="indigo"
            >
                <v-list-item v-for="(q, i) in qlist" :key="i">
                    <v-list-item-icon class="mx-0">
                        {{ i + 1 }}
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-wrap">
                            <v-row
                                no-gutters
                                justify="space-between"
                                align="center"
                            >
                                <v-col>
                                    {{
                                        q.question.charAt(0).toUpperCase() +
                                            q.question.slice(1)
                                    }}
                                </v-col>
                                <v-icon
                                    class="ml-2"
                                    v-if="q.passed"
                                    color="success"
                                    >mdi-check</v-icon
                                >
                            </v-row>
                        </v-list-item-title>
                        <v-list-item-subtitle v-text="q.aPath">
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>
<script>
export default {
    props: ["qlist", "value"],
    methods: {
        selectQuestion(q) {
            this.$emit("input", q);
        }
    }
};
</script>
