<template>
    <v-row justify="center">
        <v-col lg="8" md="10" sm="12">
            <v-col
                class="title font-weight-bold text-center"
                justify="center"
                cols="12"
            >
                Выберите перечень схем для нозологи{{
                    nozoList.length > 1 ? "й" : "и"
                }}:
            </v-col>
            <v-row
                v-if="!eachNozologyValid"
                class="title font-weight-bold red--text"
                justify="center"
            >
                В каждой нозологии должна быть выбрана хотя бы одна схема.
            </v-row>
            <v-expansion-panels class="mt-4">
                <v-scheme-list
                    v-for="nozo in nozoList"
                    :key="nozo.id"
                    :nozo="nozo"
                    :schemes="nozo.schemes"
                />
            </v-expansion-panels>
            <v-row class="mt-4" justify="center">
                <v-btn
                    v-if="eachNozologyValid"
                    class="mx-2"
                    color="warning"
                    @click="proceed"
                >
                    Утвердить перечень схем
                </v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import VSchemeList from "./Schemes/SchemeList.vue";

export default {
    components: {
        VSchemeList
    },
    data: () => ({
        nozoList: []
    }),
    mounted() {
        this.axios
            .get(`/api/projects/${this.$route.params.id}/schemes`)
            .then(resp => {
                this.nozoList = resp.data;
                this.$store.dispatch("loading", false);
            });
    },
    methods: {
        proceed() {
            this.$store.dispatch("loading", true);
            this.axios
                .get(`/api/projects/${this.$route.params.id}/proceed`)
                .then(resp => {
                    this.$emit("next", resp.data);
                });
        }
    },
    computed: {
        eachNozologyValid() {
            return this.nozoList.every(nozo =>
                nozo.schemes.some(scheme => scheme.using)
            );
        }
    }
};
</script>
