<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th
                        v-for="s in [
                            'Торговое наименование',
                            'Лекарственная форма',
                            'Упаковка (полная)'
                        ]"
                        :key="s"
                        class="text-left"
                        v-text="s"
                    />
                    <th class="text-right">Цена</th>
                    <th class="text-right">Количество</th>
                    <th class="text-right">Стоимость</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="pack in packs" :key="pack.id">
                    <td>{{ pack.name }}</td>
                    <td>{{ pack.description }}</td>
                    <td>{{ pack.form }}</td>
                    <td style="text-align: right; white-space: nowrap;">
                        {{ priceNormalized(pack.price) }}
                    </td>
                    <td style="text-align: right; white-space: nowrap;">
                        {{ pack.amount }}
                    </td>
                    <td style="text-align: right; white-space: nowrap;">
                        {{ priceNormalized(pack.total_price) }}
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>
<script>
export default {
    props: ["packs"],
    mounted() {}
};
</script>
