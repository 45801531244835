<template>
    <v-list-item dense>
        <v-list-item-content class="pa-0">
            <v-list-item-title>
                <v-row justify="space-between" no-gutters>
                    <v-card flat>
                        <v-card-title class="pa-1">
                            {{ ksg.name }}
                        </v-card-title>
                    </v-card>
                    <v-slide-x-transition group leave-absolute>
                        <v-card :key="1" flat v-if="!editMode">
                            <v-card-title class="pa-1">
                                КУ:
                                {{
                                    Number(ksg.pivot.ku).toLocaleString("ru-RU")
                                }}
                                <v-btn @click="changeKu" class="mx-2"
                                    >Изменить
                                </v-btn>
                                <v-btn @click="resetKu" icon outlined>
                                    <v-icon>mdi-backup-restore</v-icon>
                                </v-btn>
                            </v-card-title>
                        </v-card>
                        <v-card :key="2" flat v-else>
                            <v-card-title class="pa-1">
                                <v-text-field
                                    dense
                                    hide-details
                                    max="1.4"
                                    min="1"
                                    solo
                                    step="0.001"
                                    type="number"
                                    v-model="newValue"
                                ></v-text-field>
                                <v-btn @click="updateKu" class="mx-2"
                                    >Применить
                                </v-btn>
                            </v-card-title>
                        </v-card>
                    </v-slide-x-transition>
                </v-row>
            </v-list-item-title>
            <v-divider class="my-2" />
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: {
        ksg: Object
    },
    data: () => ({
        editMode: false,
        newValue: null
    }),
    methods: {
        updateKu() {
            if (this.newValue !== Number(this.ksg.pivot.ku)) {
                this.$emit("load", true);
                console.log(this.$parent);
                this.axios
                    .post(`/api/projects/${this.$route.params.id}/ksg`, {
                        ksg_id: this.ksg.id,
                        ku: this.newValue
                    })
                    .then(resp => {
                        this.ksg.pivot.ku = resp.data.pivot.ku;
                        this.$emit("load", false);
                    });
            }
            this.editMode = false;
        },
        changeKu() {
            this.newValue = Number(this.ksg.pivot.ku);
            this.editMode = true;
        },
        resetKu() {
            this.newValue = 1;
            this.updateKu();
        }
    }
};
</script>
